import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import EditFormContent from "./EditFormContent";

const EditManagerForm = () => {
  const { userDetails } = useAuthContext();
  const firm_access_self_services =
    JSON.parse(userDetails).firm_access_self_services;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (firm_access_self_services !== undefined) {
      setIsLoading(false);
    }
  }, [firm_access_self_services]);

  return (
    <>
      {isLoading ? (
        <div>
          <span>Please wait...</span>
        </div>
      ) : (
        <EditFormContent
          firm_access_self_services={firm_access_self_services}
        />
      )}
    </>
  );
};

export default React.memo(EditManagerForm);
