import React from "react";
import DocumentBox from "./DocumentBox";
import ProfileInfoTabSection from "./ProfileInfoTabSection";
import AvailabilityHolder from "./AvailabilityHolder";
import CertificationSet from "./CertificationSet";
import Language from "./Language";

const ResourceProfileBottomSection = (props) => {
  const { displayView, resourceDetails, resourceSlug } = props;

  return (
    <div className="row">
      <ProfileInfoTabSection
        displayView={displayView}
        profileDetails={resourceDetails.profile_details}
        educationDetails={resourceDetails.education_details}
        employmentDetails={resourceDetails.employment_details}
        recruiterNotes={resourceDetails.recruiter_notes}
        resourceDetails={resourceDetails}
      />
      <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-3">
        <CertificationSet
          displayView={displayView}
          certificationDetails={resourceDetails.certification_details}
          resourceSlug={resourceSlug}
        />
        <Language
          languageDetails={resourceDetails.language_details_V2}
          resourceSlug={resourceSlug}
          displayView={displayView}
          profileName={resourceDetails.profile_details.profile_name}
        />
        <AvailabilityHolder
          availabilityDetails={resourceDetails.availability_details}
        />
        {displayView === "default" ? (
          <DocumentBox documentDetails={resourceDetails.document_details} />
        ) : null}
      </div>
    </div>
  );
};

export default ResourceProfileBottomSection;
