import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import Button from "react-bootstrap/Button";
import { FIRM_IMAGE_BASE } from "../../config/env";
import { Link } from "react-router-dom";
import getLatestJobPostings from "../../apis/getLatestJobPostings";

const ActiveJobs = () => {
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (user_slug !== undefined) {
      getFavoriteProfiles();
    }
  }, [user_slug]);

  const getFavoriteProfiles = () => {
    Promise.all([getLatestJobPostings(user_slug, "")])
      .then(async ([data]) => {
        if (data?.data?.status === 0) {
          await setIsLoading(false);
        } else {
          if (data?.data?.job_list) {
            setData(data?.data?.job_list);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const displayLoader = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <div className="spinner-border" role="status" />
      </div>
    );
  };

  const displayEmptyList = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <span className="text-muted">Nothing to display here!</span>
      </div>
    );
  };

  const checkJobTitleLength = (title) => {
    if (title.length > 30) {
      return (
        <span className="text-dark" title={title}>
          {title.substring(0, 29) + "..."}
        </span>
      );
    } else {
      return (
        <span className="text-dark" title={title}>
          {title}
        </span>
      );
    }
  };

  const displayList = () => {
    return (
      <>
        <div className="card mb-2 rounded bg-light">
          <div className="p-3">
            <div className="d-flex row">
              <div className="col-5">
                <b className="text-dark fw-bold">Job Title</b>
              </div>
              <div className="col-4">
                <b className="text-dark fw-bold">Requestor</b>
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <b className="text-dark fw-bold">Action</b>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-scroll hide-scrollbar" style={{ height: 193 }}>
          {data.map(function (item) {
            return (
              <div className="d-block mb-2 rounded bg-light p-3">
                <div className="d-flex row">
                  <div className="col-5">
                    <Link to={"job/details/" + item.job_slug}>
                      <span className="text-primary fw-bold">
                        #{item.job_id}
                      </span>{" "}
                      {checkJobTitleLength(item.job_title)}
                    </Link>
                  </div>
                  <div className="col-4">
                    {item.user_primary_role !== "4" ? (
                      <div
                        className="firm-logo-sm-custom"
                        alt={item.firm_name}
                        style={{
                          backgroundImage: `url("${
                            FIRM_IMAGE_BASE + item.firm_logo
                          }")`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    ) : (
                      <span>{item.user_email}</span>
                    )}
                  </div>
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <Link to={"job/details/" + item.job_slug}>
                      <Button variant="primary" size="sm">
                        View Job
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const displayContent = () => {
    if (!data) {
      return displayEmptyList();
    } else {
      return displayList();
    }
  };

  const checkIsLoading = () => {
    if (isLoading) {
      return displayLoader();
    } else {
      return displayContent();
    }
  };

  return (
    <div className="card-custom shadow-lg border-secondary">
      <div className="card-body">
        <div className="d-flex row mb-4">
          <div className="col-8">
            <h5 className="bd-title m-0 fw-bold">Active jobs</h5>
            <span className="text-secondary fs-6">All active job listings</span>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">
            <Link to={"/job/latest-jobs"}>
              <Button variant="warning" size="sm">
                View all
              </Button>
            </Link>
          </div>
        </div>

        {checkIsLoading()}
      </div>
    </div>
  );
};

export default ActiveJobs;
