import axios from "axios";

//IMPORT API ROUTE URL
import { RESOURCE_DETAILS_BY_DOC_ID_ROUTE } from "./apiRoutes";

const getResourceDetailsByDocId = (docId) => {
  var postData = new FormData();

  postData.append("docId", docId);
  return axios
    .post(RESOURCE_DETAILS_BY_DOC_ID_ROUTE, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default getResourceDetailsByDocId;
