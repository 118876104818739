import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import favoriteProfiles from "../../apis/favoriteProfiles";
import ProfileImageSmall from "../CommonComponent/ProfileImageSmall";
import user from "../../assets/images/no-photo.png";
import { FIRM_IMAGE_BASE } from "../../config/env";

const MyFavorite = () => {
  const { userDetails } = useAuthContext();
  const firm_id = JSON.parse(userDetails)?.firm_details?.firm_id;
  const user_slug = JSON.parse(userDetails)?.user_slug;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (user_slug !== undefined) {
      getFavoriteProfiles();
    }
  }, [user_slug]);

  const getFavoriteProfiles = () => {
    Promise.all([favoriteProfiles(user_slug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          setIsLoading(false);
          setData(data?.data?.result);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const displayLoader = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <div className="spinner-border" role="status" />
      </div>
    );
  };

  const displayEmptyList = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <span className="text-muted">Nothing to display here!</span>
      </div>
    );
  };

  const displayProfilePicture = (item) => {
    if (firm_id !== "" && firm_id !== undefined) {
      if (firm_id === item.firm_id) {
        return (
          <Link to={"resources/details/" + item.user_slug}>
            <div className="d-block mr-2">
              <ProfileImageSmall imgSrc={item.profile_image_path} />
            </div>
          </Link>
        );
      } else {
        return (
          <Link to={"resources/details/" + item.user_slug}>
            <div className="profile-image-md">
              <img className="img-fluid" src={user} alt="" />
            </div>
          </Link>
        );
      }
    }
  };

  const displayProfileName = (item) => {
    if (firm_id !== "" && firm_id !== undefined) {
      if (firm_id === item.firm_id) {
        return (
          <Link to={"resources/details/" + item.user_slug}>
            <div className="d-block ms-1">
              <span className="text-dark fw-normal">{item.profile_name}</span>
            </div>
          </Link>
        );
      } else {
        return (
          <Link to={"resources/details/" + item.user_slug}>
            <div className="d-block">
              <span className="text-secondary fw-normal">Name Withheld</span>
            </div>
          </Link>
        );
      }
    } else {
      return (
        <Link to={"resources/details/" + item.user_slug}>
          <div className="d-block">
            <span className="text-secondary fw-normal">Name Withheld</span>
          </div>
        </Link>
      );
    }
  };

  const displayJobRole = (role) => {
    if (role !== null && role.trim().length !== 0) {
      return (
        <div className="d-block">
          <span className="text-info-custom fw-normal text-wrap fs-6">
            {role}
          </span>
        </div>
      );
    }
  };

  const displayList = () => {
    return (
      <>
        <div className="card mb-2 rounded bg-light">
          <div className="p-3">
            <div className="d-flex row">
              <div className="col-5">
                <b className="text-dark fw-bold">Role</b>
              </div>
              <div className="col-4">
                <b className="text-dark fw-bold">Firm</b>
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <b className="text-dark fw-bold">Action</b>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-scroll hide-scrollbar" style={{ height: 193 }}>
          {data.map(function (item, key) {
            return (
              <div className="card mb-2 rounded bg-light" key={key}>
                <div className="p-3">
                  <div className="d-flex row align-items-center">
                    <div className="col-5">
                      {displayJobRole(item.user_profile_role)}
                    </div>
                    <div className="col-4">
                      <div
                        className="firm-logo-sm-custom mt-2"
                        alt={item.firm_name}
                        style={{
                          backgroundImage: `url("${
                            FIRM_IMAGE_BASE + item.firm_logo
                          }")`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>

                    <div className="col-3  d-flex justify-content-center align-items-center">
                      <Link to={"resources/details/" + item.user_slug}>
                        <Button variant="warning" size="sm">
                          View Profile
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const displayContent = () => {
    if (!data) {
      return displayEmptyList();
    } else {
      return displayList();
    }
  };

  const checkIsLoading = () => {
    if (isLoading) {
      return displayLoader();
    } else {
      return displayContent();
    }
  };

  return (
    <div className="card-custom shadow-lg border-secondary">
      <div className="card-body">
        <div className="d-block mb-4">
          <h5 className="bd-title m-0 fw-bold">My Favorites</h5>
          <span className="text-secondary fs-6">
            Profiles you have flagged as favorites
          </span>
        </div>
        {checkIsLoading()}
      </div>
    </div>
  );
};

export default MyFavorite;
