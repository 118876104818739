import React from "react";
import TopNav from "./TopNav";

import "./header.css";
import "./navbar.css";

const Header = (props) => {
  return (
    <div className="header-wrapper">
		<script
		  type="text/javascript"
		  src="https://app.termly.io/resource-blocker/59fd5a5f-94ea-46a3-9566-ab01ca91f05d?autoBlock=on"
		></script>
      <TopNav />
    </div>
  );
};

export default Header;
