import axios from "axios";

//IMPORT API ROUTE URL
import { UPDATE_DOCUMENT } from "./apiRoutes";

const editDocument = (data) => {
  var postData = new FormData();

  postData.append("resource_slug", data.resourceSlug);
  postData.append("document_title", data.documentTitle);
  postData.append("document_id", data.documentId);

  return axios
    .post(UPDATE_DOCUMENT, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

export default editDocument;
