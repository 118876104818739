import Layout from "../../components/Layouts/WithAuth/Layout";
import AddJobPosting from "../../components/JobPosting/AddJobPosting";
import AddJobPostingByResume from "../../components/JobPosting/AddJobPostingByResume";

const CreateJob = () => {
  return (
    <Layout pageTitle={"FirmConnector :: New Job"}>
      <AddJobPostingByResume />
    </Layout>
  );
};

export default CreateJob;
