import React from "react";

import tell_company from "../../assets/images/tell_company.svg";
import ButtonLg from "../Buttons/ButtonLg";
import { useAuthContext } from "../../context/AuthContext";

const ThirdBlock = () => {
  const { isLoggedIn } = useAuthContext();

  return (
    <div className="bg-dark-custom text-white third-block">
      <div className="container">
        <div className="row flex-lg-row-reverse align-items-center py-5">
          <div className="col-12 col-sm-8 col-lg-5 p-4">
            <img
              src={tell_company}
              className="d-block mx-lg-auto img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <h1 className="display-6 mb-3 fw-bold">
              Elevate your organization
            </h1>
            <p className="text-white-custom lead">
              Experience a comprehensive and streamlined view that consolidates all your talent options into a single, intuitive interface. From identifying key skillsets to assessing availability, our platform offers a holistic perspective, enabling you to make informed decisions and strategically navigate your talent pool. 
			
			</p>
			<p className="text-white-custom lead">  
			  Elevate your workforce planning with efficiency and precision, ensuring that you have the right people at the right time for unparalleled business success. 
            </p>            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdBlock;
