import React from "react";
import TopProfileSection from "./TopProfileSection";

const ClientScreen = () => {
  return (
    <>
      <TopProfileSection />
    </>
  );
};

export default ClientScreen;
