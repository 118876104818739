import axios from "axios";

//IMPORT API ROUTE URL
import { GET_RESOURCE_BASIC_DETAILS_ROUTE } from "./apiRoutes";

const getResourceBasicDetails = (r_slug, jobId) => {
  var postData = new FormData();

  postData.append("resource_slug", r_slug);

  return axios
    .post(GET_RESOURCE_BASIC_DETAILS_ROUTE, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default getResourceBasicDetails;
