import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import fetchAllRawSkills from "../../../apis/fetchAllRawSkills";
import addSkillToUser from "../../../apis/addSkillToUser";
import removeSkillToUser from "../../../apis/removeSkillToUser";
import getSkillsBySearchText from "../../../apis/getSkillsBySearchText";
import { useAuthContext } from "../../../context/AuthContext";
import { BsTrash, BsPlus } from "react-icons/bs";
import InputLebelComponent from "../../InputLebel/InputLebelComponent";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";

const SkillSet = (props) => {
  const {
    skillDetails,
    skillCount,
    skillFormattedSet,
    resourceSlug,
    profileName,
    displayView,
  } = props;

  const MySwal = swalWithBootstrapButtons(Swal);
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const user_primary_role = JSON.parse(userDetails).user_primary_role;
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoadingSkillEdit, setIsLoadingSkillEdit] = useState(true);
  const [rawSkills, setRawSkills] = useState(false);
  const [skillSearchText, setSkillSearchText] = useState("");
  const [searchedSkills, setSearchedSkills] = useState(false);
  const [isLoadingSkillSearch, setIsLoadingSkillSearch] = useState(false);
  const [filterTextStatus, setFilterTextStatus] = useState(
    "Your search skills will be displayed here"
  );
  const [isAddingSkill, setIsAddingSkill] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    window.location.reload();
  };

  const handleShowEditModal = () => {
    handleClose();
    setShowEditModal(true);
  };

  useEffect(() => {
    if (showEditModal) {
      setIsLoadingSkillEdit(true);
      fetchSkills();
    }
  }, [showEditModal]);

  const fetchSkills = () => {
    Promise.all([fetchAllRawSkills(resourceSlug, user_slug)])
      .then(async ([data]) => {
        if (data?.data?.status) {
          setRawSkills(data?.data?.skills);
        }
        setIsLoadingSkillEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(false);
  }, [skillDetails, skillCount, skillFormattedSet]);

  const displayBlockContent = () => {
    if (isLoading) {
      return <LoadingPageSm />;
    } else {
      return renderSkillSet();
    }
  };

  const renderSkillSet = () => {
    if (skillDetails !== null && skillDetails) {
      return (
        <div>
          <div className="skill-holder flex-column mb-3">
            {skillDetails.map((item, key) => {
              return displaySkillItem(item, key);
            })}
          </div>
          {skillCount.skill_count > 7 ? (
            <div className="d-flex justify-content-end">
              <Button variant="primary" size="sm" onClick={() => handleShow()}>
                View All Skills
              </Button>
            </div>
          ) : null}

          {displaySkillModalContent()}
        </div>
      );
    } else {
      return <span className="text-muted">Nothing to display here!</span>;
    }
  };

  const displaySkillItem = (item) => {
    return (
      <div
        className="skill-wrapper-dark my-1 me-1 mb-2"
        key={item.skill_id.toString()}
      >
        <span>{item.skill_name}</span>
      </div>
    );
  };

  const displaySearchSkillItem = (item) => {
    return (
      <button
        className="btn my-1 me-1 mb-2 d-flex btn-success"
        key={item.skill_id.toString()}
        disabled={isAddingSkill}
        onClick={() => addSkill(item.skill_id, { item })}
      >
        <span>{item.skill_name}</span>
        <div className="ms-2 pe-none cursor-pointer">
          <BsPlus className={`text-light`} />
        </div>
      </button>
    );
  };

  const addSkill = (id, item) => {
    setIsAddingSkill(true);
    Promise.all([addSkillToUser(resourceSlug, id)])
      .then(([data]) => {
        if (data?.data?.status) {
          setSearchedSkills((current) =>
            current.filter((skills) => skills.skill_id !== id)
          );

          setRawSkills((oldArray) => [...oldArray, item.item]);

          toast.success(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        }

        setIsAddingSkill(false);
      })
      .catch((err) => {
        setIsAddingSkill(false);
        console.log(err);
      });
  };

  const removeSkill = (id, name) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "that you want to remove " + name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "var(--black)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        processRemoveSkill(id);
      }
    });
  };

  const processRemoveSkill = (id) => {
    setIsAddingSkill(true);
    Promise.all([removeSkillToUser(resourceSlug, id)])
      .then(([data]) => {
        if (data?.data?.status) {
          setRawSkills((current) =>
            current.filter((skills) => skills.skill_id !== id)
          );

          toast.success(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        }

        setIsAddingSkill(false);
      })
      .catch((err) => {
        setIsAddingSkill(false);
        console.log(err);
      });
  };

  const displayRawSkillItem = (item) => {
    return (
      <button
        className="btn my-1 me-1 mb-2 d-flex btn-dark"
        key={item.skill_id.toString()}
        disabled={isAddingSkill}
        onClick={() => removeSkill(item.skill_id, item.skill_name)}
      >
        <span className="">{item.skill_name}</span>
        <div className="ms-2 pe-none cursor-pointer">
          <BsTrash className={`text-light`} />
        </div>
      </button>
    );
  };

  const displaySkillEditModalContent = () => {
    return (
      <Modal
        fullscreen
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="fullscreen"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-block">
            <span className="fw-bold-custom">
              Edit Skills for {profileName}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {displayNewSkillAdd()}
          {displayAllRawSkills()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseEditModal} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleTextChange = (e) => {
    setSkillSearchText(e.target.value);
  };

  useEffect(() => {
    if (skillSearchText.trim().length > 2) {
      const getData = setTimeout(() => {
        setIsLoadingSkillSearch(true);
        getSkillsWithSearchText();
      }, 500);

      return () => clearTimeout(getData);
    } else {
      setFilterTextStatus("Your search skills will be displayed here");
      setSearchedSkills(false);
    }
  }, [skillSearchText]);

  const getSkillsWithSearchText = () => {
    Promise.all([getSkillsBySearchText(skillSearchText, resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          setSearchedSkills(data?.data?.skills);
        } else {
          setSearchedSkills(false);
          setFilterTextStatus(data?.data?.message);
        }

        setIsLoadingSkillSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displayNewSkillAdd = () => {
    return (
      <div className="d-block">
        <div className="form-input-holder">
          <InputLebelComponent title="Search Skills" />
          <div className="d-block">
            <input
              type="email"
              className="form-control"
              id="new-skill"
              placeholder="Search skill to add"
              onChange={handleTextChange}
              value={skillSearchText}
              autoComplete="off"
            />
          </div>
          <div className="border-dark p-2 rounded my-2 bg-light">
            {displaySearchContent()}
          </div>
        </div>
      </div>
    );
  };

  const displaySearchContent = () => {
    if (isLoadingSkillSearch) {
      return (
        <div className="mt-2">
          <LoadingPageSm />
        </div>
      );
    } else {
      if (!searchedSkills) {
        return <span>{filterTextStatus}</span>;
      } else {
        return displaySearchedSkillItems();
      }
    }
  };

  const displaySearchedSkillItems = () => {
    return (
      <>
        {searchedSkills ? (
          <>
            <div className="my-2">
              <span>Click on (+) button to add new skill</span>
            </div>

            <div className="d-block raw-skill-holder">
              <div className="d-flex flex-wrap">
                {searchedSkills.map((item, key) => {
                  return displaySearchSkillItem(item, key);
                })}
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const displayAllRawSkills = () => {
    if (isLoadingSkillEdit) {
      return <LoadingPageSm />;
    } else {
      return renderRawSkillSet();
    }
  };

  const renderRawSkillSet = () => {
    return (
      <>
        <div className="d-block">
          <span className="fw-bold-custom">Current Skills</span>
        </div>
        <div className="d-block raw-skill-holder">
          {rawSkills ? (
            <div className="d-flex flex-wrap">
              {rawSkills.map((item, key) => {
                return displayRawSkillItem(item, key);
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const displaySkillModalContent = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header>
          <div className="d-block">
            <span className="fw-bold-custom">My Skills</span>
          </div>
        </Modal.Header>
        <Modal.Body>{displayAllSkills()}</Modal.Body>
        <Modal.Footer>
          {displayView === "default" && user_primary_role !== "3" ? (
            <Button variant="success" onClick={handleShowEditModal} size="sm">
              Edit Skill
            </Button>
          ) : null}

          <Button variant="primary" onClick={handleClose} size="sm">
            Close Skill
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayAllSkills = () => {
    if (skillDetails !== null && skillDetails) {
      return (
        <div className="d-block">
          {skillFormattedSet.map((item, key) => {
            return (
              <div
                className="d-block mb-1"
                key={item.skill_sub_category_id.toString()}
              >
                <div className="d-block">
                  <div className="d-block mb-1">
                    <span className="fw-bold-custom">
                      {item.skill_category_title}
                    </span>
                  </div>
                  <div className="d-block border-dark p-1 rounded">
                    {displayAllSkill(item.skill_set)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <span className="text-muted">Nothing to display here!</span>;
    }
  };

  const displayAllSkill = (skillArray) => {
    if (skillArray.length > 0) {
      return (
        <div className="d-flex flex-wrap">
          {skillArray.map((item, key) => {
            return displaySkillItem(item, key);
          })}
        </div>
      );
    }
  };

  return (
    <>
      <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-3 top-card-section">
        <div className="card-custom h-100">
          <div className="card-body ">
            <div className="d-block mb-3">
              <span className="fw-bold">My Skills</span>
            </div>
            {displayBlockContent()}
          </div>
        </div>
      </div>
      {displaySkillEditModalContent()}
    </>
  );
};

export default SkillSet;
