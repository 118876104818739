import Layout from "../../components/Layouts/WithoutAuth/Layout";
import StaticPageHeaderComponent from "../../components/PageCommonComponent/StaticPageHeaderComponent";

const NewPublicClient = () => {
  return (
    <Layout pageTitle={"FirmConnector :: New Public Client"}>
      <div className="d-block bg-white-custom">
        <StaticPageHeaderComponent
          title="New Client Training"
          description_sm={""}
        />
      </div>

      <div className="container bg-white-custom py-5">
        <p className="display-6 fw-bold">Welcome aboard!</p>
        <div className="pt-1">
          <span>
            We’re thrilled to guide you through some of the great functionality
            now at your fingertips! To ensure you get the most from your
            account, click below for a quick self-guided tour!
          </span>
        </div>
        <div className="my-6" style={{ width: "100%", height: "100%" }}>
          <iframe
            title="client demo"
            src={"https://api.firmconnector.com/demo.html"}
            style={{ width: "100%", height: 800 }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default NewPublicClient;
