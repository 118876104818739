const API_BASE = "https://api.firmconnector.com/index.php/";
// const API_BASE = "http://localhost/api.firmconnector.com/index.php";
// const API_BASE = "https://testapi.firmconnector.com/index.php/";

const ASSETS_BASE = "https://api.firmconnector.com/assets/";
// const ASSETS_BASE = "https://localhost/api.firmconnector.com/assets/";
// const ASSETS_BASE = "https://testapi.firmconnector.com/assets/";

const PROFILE_IMAGE_BASE = "https://api.firmconnector.com/";
// const PROFILE_IMAGE_BASE = "https://localhost/api.firmconnector.com/";
// const PROFILE_IMAGE_BASE = "https://testapi.firmconnector.com/";

const FIRM_IMAGE_BASE = "https://api.firmconnector.com/";
// const FIRM_IMAGE_BASE = "https://localhost/api.firmconnector.com/";
// const FIRM_IMAGE_BASE = "https://testapi.firmconnector.com/";

export { API_BASE, ASSETS_BASE, PROFILE_IMAGE_BASE, FIRM_IMAGE_BASE };
