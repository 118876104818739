import React from "react";

import banner_image from "../../assets/images/banner_left.svg";
import "./home.css";

const TopBannerComponent = () => {
  return (
    <div className="jumbotron bg-black-custom top-banner py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-7">
            <div className="d-block mb-4">
              <h1 className="display-4 text-white fw-bold">
                Unlock your talent pipeline
              </h1>
            </div>
            <div className="d-block mb-4">
              <p className="lead text-white-custom mb-4">
             Gain profound insights into the professionals available within your talent network through FirmConnector. 
			 </p>
			 <p className="lead text-white-custom mb-4">
			 Realize the potential of your in-house workforce while seamlessly identifying and engaging with exceptional individuals through a unified interface that seamlessly integrates your trusted vendors and partners.
			 

              </p>
            </div>
            {/* <div className="d-block">
              <ButtonLg
                className="btn-success-custom"
                role="button"
                title="Click here to learn more"
                type="button"
                to="sign-in"
              />
            </div> */}
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-lg-end d-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <img src={banner_image} className="img-fluid" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBannerComponent;
