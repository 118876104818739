import axios from "axios";

//IMPORT API ROUTE URL
import { UPDATE_PROFILE_PASSWORD } from "./apiRoutes";

const updateProfilePassword = (data) => {
  var postData = new FormData();

  postData.append("user_slug", data.userSlug);
  postData.append("old_password", data.oldPassword);
  postData.append("new_password", data.newPassword);

  return axios
    .post(UPDATE_PROFILE_PASSWORD, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default updateProfilePassword;
