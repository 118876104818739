import React from "react";

const PricingScreen = () => {
  return (
    <div>
      <h1>This is PricingScreen screen</h1>
    </div>
  );
};

export default PricingScreen;
