import React, { useEffect, useState } from "react";
import ResourceProfile from "../../components/Resources/ResourceProfile/ResourceProfile";
import Layout from "../../components/Layouts/WithAuth/Layout";
import getResourceDetailsByDocId from "../../apis/getResourceDetailsByDocId";
import Alert from "react-bootstrap/Alert";

const ResourceEditScreen = (props) => {
  const { resourceSlug } = props.match.params;
  const [isChecking, setIsChecking] = useState(true);
  const [hasResult, setHasResult] = useState(false);
  const [apiMessage, setApiMessage] = useState(false);

  useEffect(() => {
    if (resourceSlug.includes("document-")) {
      getResourceDetailsByDoc();
    } else {
      setIsChecking(false);
      setHasResult(true);
    }
  }, [resourceSlug]);

  const getResourceDetailsByDoc = () => {
    Promise.all([getResourceDetailsByDocId(resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.resource_details) {
          window.location.href =
            "https://www.firmconnector.com/resources/details/" +
            data?.data?.resource_details.user_slug;
        } else {
          setIsChecking(false);
          setHasResult(false);
          setApiMessage(data.data.message);
        }
      })
      .catch((err) => {
        setIsChecking(false);
        setHasResult(false);
        setApiMessage("Something went wrong. Please try again later.");
        console.log(err);
      });
  };

  return (
    <Layout pageTitle={"FirmConnector :: My Candidate"}>
      {isChecking ? (
        <div>
          <span>Please wait...</span>
        </div>
      ) : hasResult ? (
        <ResourceProfile resourceSlug={resourceSlug} />
      ) : (
        <Alert key={"info"} variant={"info"}>
          {apiMessage}
        </Alert>
      )}
    </Layout>
  );
};

export default ResourceEditScreen;
