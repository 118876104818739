import React from "react";
import BottomComponent from "./BottomComponent";
import BlockHeader from "../Headers/BlockHeader";

const DashboardComponent = () => {
  return (
    <div className="d-block">
      <BlockHeader title={"Dashboard"} subText={""} />
      <BottomComponent />
    </div>
  );
};

export default DashboardComponent;
