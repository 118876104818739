import React, { useEffect, useState } from "react";
import ImageIconSmHolder from "../../Iconcontainer/ImageIconSmHolder";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import { Button } from "react-bootstrap";

import envelope from "../../../assets/images/email.svg";
import mobile from "../../../assets/images/mobile.svg";
import phone from "../../../assets/images/phone.svg";

import { FIRM_IMAGE_BASE } from "../../../config/env";

import { useAuthContext } from "../../../context/AuthContext";
import sendClientQuery from "../../../apis/sendClientQuery";

import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";
import updateIsAdvertised from "../../../apis/updateIsAdvertised";
import updateSelfService from "../../../apis/updateSelfService";

const GeneralInfo = (props) => {
  const { displayView, contactDetails, resourceDetails, rmDetails } = props;
  const MySwal = swalWithBootstrapButtons(Swal);

  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const user_id = JSON.parse(userDetails).user_id;
  const firm_id = JSON.parse(userDetails).firm_details?.firm_id;
  const user_primary_role = JSON.parse(userDetails).user_primary_role;
  const firmType = JSON.parse(userDetails).firm_details?.firm_type;
  const firmId = JSON.parse(userDetails).firm_details?.firm_id;

  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [inquiry, setInquiry] = useState("");
  const [isQuerySendButton, setIsQuerySendButton] = useState(false);
  const [querySendButtonText, setQuerySendButtonText] = useState("Submit");
  const [isAdvertised, setIsAdvertised] = useState(true);
  const [firmSelfServiceIds, setFirmSelfServiceIds] = useState(true);

  useEffect(() => {
    setIsProfileLoading(false);
    setIsAdvertised(resourceDetails.is_advertised);
    setFirmSelfServiceIds(resourceDetails.firm_ids_self_services);
  }, [contactDetails, resourceDetails]);

  const displayGeneralInfoBlock = () => {
    return (
      <div className="profile-info-block-holder">
        <div className="d-flex align-items-center">
          <div className="info-icon-holder">
            <ImageIconSmHolder imageUrl={envelope} />
          </div>
          <div className="d-block pb-1 pt-1">{displayEmail()}</div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <div className="d-flex align-items-center">
              <div className="info-icon-holder">
                <ImageIconSmHolder imageUrl={mobile} />
              </div>
              <div className="d-block pb-1 pt-1">{displayMobile()}</div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <div className="d-flex align-items-center">
              <div className="info-icon-holder">
                <ImageIconSmHolder imageUrl={phone} />
              </div>
              <div className="d-block pb-1 pt-1">{displayOfficePhone()}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0">
            {user_primary_role !== "3" ? (
              <>
                {displayReportTo()}
                {displayAdvertise()}
                {firmType === "2" ? displaySelfService() : null}
              </>
            ) : null}
          </div>
          <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 ">
            <div className="d-block">
              <span>Added by: {rmDetails?.rm_name}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const checkSelfServices = (firmIds) => {
    if (firmIds !== null) {
      const fIds = firmIds.split(", ");

      if (fIds.indexOf(firmId) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const displaySelfService = () => {
    return (
      <div className="col-12 d-flex">
        {user_id === rmDetails.resource_manager_id ? (
          <div className="form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              onChange={() =>
                updateSelfServiceEdit(
                  resourceDetails.user_slug,
                  resourceDetails.profile_name
                )
              }
              checked={checkSelfServices(firmSelfServiceIds)}
            />
          </div>
        ) : null}

        <div className="d-block">
          <span className="text-muted-custom">Self Service</span>
        </div>
      </div>
    );
  };

  const displayAdvertise = () => {
    return (
      <>
        <div className="col-12 d-flex mb-4">
          {user_id === rmDetails.resource_manager_id ||
          resourceDetails.firm_id === firm_id ? (
            <div className="form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                onChange={() =>
                  updateAdvertised(isAdvertised, resourceDetails.user_slug)
                }
                checked={isAdvertised === "1" ? true : false}
              />
            </div>
          ) : null}

          <div className="d-block">
            <span className="text-muted-custom">Visible to Clients</span>
          </div>
        </div>
      </>
    );
  };

  const updateAdvertised = (advertised, resourceSlug) => {
    Promise.all([updateIsAdvertised(user_slug, advertised, resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          if (advertised === "0") {
            advertised = "1";
          } else {
            advertised = "0";
          }

          setIsAdvertised(advertised);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSelfServiceEdit = (resourceSlug, name) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `You want to update self-service access for ${name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "var(--black)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        processUpdateSelfService(resourceSlug);
      } else {
        return false;
      }
    });
  };

  const processUpdateSelfService = (resourceSlug) => {
    Promise.all([updateSelfService(user_slug, resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          setFirmSelfServiceIds(data?.data?.resource?.firm_ids_self_services);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displayEmail = () => {
    if (
      contactDetails.user_email !== null &&
      contactDetails.user_email !== ""
    ) {
      return <span>{contactDetails.user_email}</span>;
    } else {
      return <span className="text-muted">Email not available!</span>;
    }
  };

  const displayMobile = () => {
    if (contactDetails.mobile !== null && contactDetails.mobile !== "") {
      return <span>{contactDetails.mobile}</span>;
    } else {
      return <span className="text-muted">Not available!</span>;
    }
  };

  const displayOfficePhone = () => {
    if (
      contactDetails.office_phone !== null &&
      contactDetails.office_phone !== ""
    ) {
      return <span>{contactDetails.office_phone}</span>;
    } else {
      return <span className="text-muted">Not available!</span>;
    }
  };

  const displayReportTo = () => {
    if (resourceDetails.firm_type === "1") {
      return (
        <div className="d-flex align-items-center">
          <div className="info-icon-holder">
            <ImageIconSmHolder imageUrl={envelope} />
          </div>
          <div className="d-block">
            <div className="">
              <span className="fw-medium-custom text-x-x-custom">
                Reports to
              </span>
            </div>
            <div className="">
              <span className="text-dark text-sm-custom">
                {resourceDetails.report_to_user_profile_name
                  ? resourceDetails.report_to_user_profile_name +
                    " ( " +
                    resourceDetails.RM_profile_role +
                    " )"
                  : "Nothing found!"}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const changehandler = (e) => {
    setInquiry(e.target.value);
  };

  const handleData = () => {
    setIsQuerySendButton(true);
    setQuerySendButtonText("Sending...");

    if (inquiry.trim().length > 0) {
      let formData = {
        user_slug: user_slug,
        resource_slug: resourceDetails?.user_slug,
        message: inquiry,
      };

      try {
        sendClientQuery(formData).then(async (data) => {
          if (data?.data) {
            if (data.data.status === 1) {
              await MySwal.fire({
                title: <strong>Success</strong>,
                html: <i>{data.data.message}</i>,
                icon: "success",
              });
              await setInquiry("");
            } else {
              await MySwal.fire({
                title: <strong>Error</strong>,
                html: <i>{data.data.message}</i>,
                icon: "danger",
              });
              await setInquiry("");
            }
          } else {
            await MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>{"Something went wrong. Please try again later."}</i>,
              icon: "danger",
            });
          }
        });

        setIsQuerySendButton(false);
        setQuerySendButtonText("Submit");
      } catch (error) {
        setIsQuerySendButton(false);
        setQuerySendButtonText("Submit");
      }
    } else {
      setIsQuerySendButton(false);
      setQuerySendButtonText("Submit");
    }
  };

  const inquireView = () => {
    return (
      <div className="profile-info-block-holder">
        <div className="mb-2">
          <textarea
            className="form-control"
            id="inquire-text"
            rows="3"
            placeholder={`Ask a question about this candidate to ${rmDetails?.rm_name} at ${resourceDetails?.firm_name}.`}
            onChange={changehandler}
            value={inquiry}
          ></textarea>
        </div>

        <div>
          <div className="d-flex justify-content-end">
            <Button
              variant={"primary"}
              size="sm"
              onClick={handleData}
              disabled={isQuerySendButton}
            >
              {querySendButtonText}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const displayBlockContent = () => {
    if (isProfileLoading) {
      return <LoadingPageSm />;
    } else {
      return (
        <div className="d-block">
          {displayView === "default"
            ? displayGeneralInfoBlock()
            : inquireView()}

          {user_primary_role !== "3" ? (
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div
                className="firm-logo-lg"
                style={{
                  backgroundImage: `url("${
                    FIRM_IMAGE_BASE + resourceDetails.firm_logo
                  }")`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <div className="col-12 col-lg-5 col-xl-5 col-xxl-5 mb-3 top-card-section">
      <div className="card-custom h-100">
        <div className="card-body">{displayBlockContent()}</div>
      </div>
    </div>
  );
};

export default GeneralInfo;
