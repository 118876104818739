import React from "react";
import { Link } from "react-router-dom";
import { PROFILE_IMAGE_BASE } from "../../config/env";

const HeaderTopUserBlock = (props) => {
  const { userDetails } = props;
  const loggedinUserDetails = JSON.parse(userDetails);
  const profileName = `${loggedinUserDetails.first_name} ${loggedinUserDetails.last_name}`;
  return (
    <Link to={"dashboard"}>
      <div className="d-flex align-items-center">
        <div className="profile-image-sm-rounded">
          {loggedinUserDetails.profile_image_path !== null ? (
            <img
              className="img-fluid"
              src={PROFILE_IMAGE_BASE + loggedinUserDetails.profile_image_path}
              alt={profileName}
            />
          ) : (
            <img
              className="img-fluid"
              src={
                PROFILE_IMAGE_BASE +
                "assets/uploads/profile/default/default.png"
              }
              alt={"My Profile"}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export default HeaderTopUserBlock;
