import React, { useState, useRef } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "react-bootstrap";
import { AlertInfo, AlertDanger, AlertSuccess } from "../Alerts/Alert";
import { Link } from "react-router-dom";
import BlockHeader from "../Headers/BlockHeader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "bootstrap";
import CustomConfirmationModal from "../Resources/CustomConfirmationModal";
import createJobViaParsing from "../../apis/createJobViaParsing";
import createJobViaContentEntry from "../../apis/createJobViaContentEntry";

const AddJobPostingByJobParsing = () => {
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const user_primary_role = JSON.parse(userDetails).user_primary_role;

  const [isJobParsingButtonDisabled, setIsJobParsingButtonDisabled] =
    useState(false);
  const [hasJobParsingUploadError, setHasJobParsingUploadError] =
    useState(false);
  const [isValidJobParsingUpload, setIsValidJobParsingUpload] = useState(false);
  const [errorJobParsingUploadMessage, setErrorJobParsingUploadMessage] =
    useState(false);
  const [successJobParsingUploadMessage, setSuccessJobParsingUploadMessage] =
    useState(false);
  const [modal, setModal] = useState(null);
  const [isDrag, setIsDrag] = useState(false);
  const [dropFiles, setDropFiles] = useState([]);
  const inputFileRef = useRef(null);
  const [jobManualEntry, setJobManualEntry] = useState("");
  const [jobManualEntryUploading, setJobManualEntryUploading] = useState(false);

  const fileSelect = () => {
    inputFileRef.current.click();
  };

  const onFileSelect = (e) => {
    const files = e.target ? e.target.files : e.dataTransfer.files;
    const droppedFiles = Array.from(files);
    if (e.target.files.length + dropFiles.length > 20) {
      toast.warn(
        "Max limit reached cannot upload more than 20 files at a time.",
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    }

    const filesWithUrls = droppedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      error: "",
      success: "",
    }));

    setDropFiles([...dropFiles, ...filesWithUrls]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDrag(true);
    e.dataTransfer.dropEffect = "copy";
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDrag(false);
  };

  const onConfirm = () => {
    // Remove error files from dropFiles
    const validFiles = dropFiles.filter((file) => !file.error);

    setDropFiles([...validFiles]);
    modal.hide();
  };
  const onCancel = () => {
    const validFiles = dropFiles.filter((file) => file.error);
    setDropFiles([...validFiles]);
  };
  const onDrop = (e) => {
    e.preventDefault();
    setIsDrag(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const filesWithUrls = droppedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      error: "",
      success: "",
    }));
    setDropFiles([...dropFiles, ...filesWithUrls]);
    if (hasErrorFiles()) {
      let myModal = new Modal(document.getElementById("staticBackdrop"), {
        keyboard: false,
      });
      setModal(myModal);
      myModal.show();
    }
  };

  const hasErrorFiles = () => {
    return dropFiles.some((file) => file.error);
  };

  const uploadFiles = async () => {
    const updatedFiles = [];
    for (let i = 0; i < dropFiles.length; i++) {
      try {
        const res = await uploadJobParsing(dropFiles[i]);
        updatedFiles.push({
          ...dropFiles[i],
          success: res,
        });
      } catch (err) {
        let errMsg = JSON.parse(err);
        updatedFiles.push({
          ...dropFiles[i],
          error: errMsg.message,
          user_slug: errMsg.user_slug,
        });
      }
    }

    const remainingFiles = updatedFiles.filter((file) => !file.success);
    setDropFiles(remainingFiles);
  };

  const deleteFile = (index) => {
    setDropFiles(dropFiles.filter((_, i) => i !== index));
  };

  const waringErr = (
    <div>
      <span>
        Click <span className="fw-bold">OK</span> to remove the error messages
        below
      </span>
      <br />
      <br />
      <span>
        <span className="fw-bold ">Note: </span>
        <span className="text-danger">
          Error messages must be cleared before uploading additional resumes for
          profile creation.
        </span>
      </span>
    </div>
  );

  const uploadJobParsing = ({ file }) => {
    return new Promise((resolve, reject) => {
      setIsJobParsingButtonDisabled(true);
      let errMessage = [];
      let succMessage = [];
      let formData = {
        user_slug: user_slug,
        file: file,
      };
      try {
        createJobViaParsing(formData).then(async (data) => {
          setIsJobParsingButtonDisabled(false);
          if (data?.data) {
            if (data.data.status === 0) {
              errMessage.push(data.data.message);
              setErrorJobParsingUploadMessage(errMessage);
              toast.error(`${errMessage}`, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
              });
              reject(JSON.stringify(data?.data));
            } else {
              setIsJobParsingButtonDisabled(false);
              succMessage.push(data.data.message);

              toast.success(`${succMessage}`, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
              });
              resolve(data.data.message);
            }
          } else {
            toast.warn("Something wrong happened. Please try again later.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const displayMainContent = () => {
    return displayFormBlock();
  };

  const displayTopBlock = () => {
    return (
      <>
        <div className="d-block">
          <BlockHeader
            title={"Create a new job posting"}
            subText={
              "Populate information below and select which firms to help find candidates"
            }
          />
        </div>
        <div className="d-flex justify-content-end my-3">
          <Link to="/job/latest-jobs">
            <Button variant="outline-secondary" size="sm">
              Active Jobs
            </Button>
          </Link>
          <Link to="/job/my-jobs">
            <Button variant="outline-secondary" size="sm" className="mx-2">
              My Posted Jobs
            </Button>
          </Link>
          <Link to="/create-job">
            <Button variant="primary" size="sm" className="me-2">
              Via Parsing
            </Button>
          </Link>
          <Link to="/create-job-manually">
            <Button variant="outline-secondary" size="sm">
              Manual Entry
            </Button>
          </Link>
        </div>
      </>
    );
  };

  const displayJobParsingUploadStatusMessage = () => {
    return (
      <div className="d-block mt-4">
        {displayJobParsingUploadErrorMessage()}{" "}
        {displayJobParsingUploadSuccessMessage()}
      </div>
    );
  };

  const displayJobParsingUploadErrorMessage = () => {
    if (hasJobParsingUploadError) {
      return (
        <AlertDanger title={"Oops"} message={errorJobParsingUploadMessage} />
      );
    }
  };

  const displayJobParsingUploadSuccessMessage = () => {
    if (isValidJobParsingUpload) {
      return (
        <AlertSuccess
          title={"Success"}
          message={successJobParsingUploadMessage}
        />
      );
    }
  };

  const __handleChnageJobContent = (event) => {
    setJobManualEntry(event.target.value);
  };

  const uploadJobContent = () => {
    setJobManualEntryUploading(true);
    try {
      let formData = {
        user_slug: user_slug,
        content: jobManualEntry,
      };

      let errMessage = [];
      let succMessage = [];

      createJobViaContentEntry(formData).then(async (data) => {
        if (data?.data) {
          setJobManualEntryUploading(false);
          if (data.data.status === 0) {
            errMessage.push(data.data.message);
            setErrorJobParsingUploadMessage(errMessage);
            toast.error(`${errMessage}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              progress: undefined,
              theme: "light",
            });
            setJobManualEntry("");
          } else {
            setIsJobParsingButtonDisabled(false);
            succMessage.push(data.data.message);

            toast.success(`${succMessage}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              progress: undefined,
              theme: "light",
            });
            setJobManualEntry("");
          }
        } else {
          setJobManualEntryUploading(false);
          toast.warn("Something wrong happened. Please try again later.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
    } catch (error) {
      setJobManualEntryUploading(false);
      console.log(error);
    }
  };

  const displayJobParsingUpload = () => {
    return (
      <>
        <div className="d-block">
          <CustomConfirmationModal
            message={waringErr}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />

          {isJobParsingButtonDisabled ? (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="d-block my-2">
          {displayJobParsingUploadStatusMessage()}
        </div>
        <div
          className=" card12 p-4 rounded shadow"
          style={{ width: "80%", overflow: "hidden", margin: "auto" }}
        >
          <div className="text-center">
            <p className="text-primary fw-bold">Drag and Drop files here</p>
          </div>

          <div
            className="drag-area12 rounded-3 bg-light d-flex justify-content-center align-items-center "
            style={{ height: "250px" }}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            {isDrag ? (
              <>
                <span className="select12 ">Drop files here</span>
              </>
            ) : (
              <>
                {dropFiles.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                    <i className="bi bi-upload file-icon12"></i>
                    <span className="drop-file12">Drop files here or</span>
                    <span
                      className="select12 d-flex gap-2 btn btn-primary"
                      role="button"
                      onClick={fileSelect}
                    >
                      <i className="bi bi-file-earmark-plus"></i>
                      <span>Click to upload</span>
                    </span>
                  </div>
                ) : (
                  <div className="filee-container12">
                    {dropFiles.map((file, index) => (
                      <div className="file-preview12" key={index}>
                        <ul className="ul-file-list12">
                          <li>
                            {file.file.name}

                            <span className="text-danger">{file.error}</span>
                            {file.error && file.user_slug ? (
                              <span>
                                Click{" "}
                                <a
                                  href={`resources/details/${file.user_slug}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  here
                                </a>{" "}
                                to view existing profile.
                              </span>
                            ) : (
                              ""
                            )}
                            <span
                              className="delete12"
                              onClick={() => deleteFile(index)}
                            >
                              &times;
                            </span>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            <input
              type="file"
              name="file"
              className="file12"
              multiple
              ref={inputFileRef}
              onChange={onFileSelect}
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </div>

          <div
            className={
              isDrag || dropFiles.length === 0
                ? "file-disable12"
                : "file-button12"
            }
          >
            <button
              onClick={uploadFiles}
              disabled={isDrag || dropFiles.length === 0}
            >
              Upload
            </button>
          </div>
          <div className="d-block mt-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              OR You can paste your job requirements here
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="10"
              value={jobManualEntry}
              onChange={__handleChnageJobContent}
            />
          </div>
          <div
            className={
              !jobManualEntry?.trim().length || jobManualEntryUploading
                ? "file-disable12"
                : "file-button12"
            }
          >
            <button
              onClick={uploadJobContent}
              disabled={
                jobManualEntry?.trim().length > 0 || !jobManualEntryUploading
                  ? false
                  : true
              }
            >
              Create Job
            </button>
          </div>
        </div>
      </>
    );
  };

  const displayFormBlock = () => {
    if (user_primary_role === "2" || user_primary_role === "4") {
      return (
        <>
          {displayTopBlock()}
          {displayJobParsingUpload()}
        </>
      );
    } else {
      return (
        <AlertInfo
          title={"Note"}
          message={"You do not have access to create Job"}
        />
      );
    }
  };

  return <div className="d-block">{displayMainContent()}</div>;
};

export default AddJobPostingByJobParsing;
