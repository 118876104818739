import React, { useState, useEffect, useCallback } from "react";
import { Button, Table } from "react-bootstrap";
import getMatchingCandidateV2 from "../../apis/getMatchingCandidateV2";
import getUpdateProposeFor from "../../apis/getUpdateProposeFor";

import ProfileImageMd from "../../components/CommonComponent/ProfileImageMd";
import { Link } from "react-router-dom";

const AIMatchingUI = (props) => {
  const { jobSlug, user_slug, triggerUpdateProposeFor, jobDetails } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [dataArray, setDataArray] = useState();
  const [jobScore, setJobScore] = useState(0);
  const [eduScore, setEduScore] = useState(0);
  const [skillScore, setSkillScore] = useState(0);
  const [industryScore, setIndustryScore] = useState(0);
  const [lngScore, setLngScore] = useState(0);
  const [mlScore, setMlScore] = useState(0);
  const [cScore, setCScore] = useState(0);
  const [exScore, setExScore] = useState(0);
  const [filterData, setFilterData] = useState(false);
  const [filterArray, setFilterArray] = useState([
    {
      name: "Education",
      value: 0,
      type: "edu",
      remove: 0,
    },
    {
      name: "Job Titles",
      value: 0,
      type: "jt",
      remove: 0,
    },
    {
      name: "Skills",
      value: 0,
      type: "sk",
      remove: 0,
    },
    {
      name: "Industries",
      value: 0,
      type: "in",
      remove: 0,
    },
    {
      name: "Management Level",
      value: 0,
      type: "ml",
      remove: 0,
    },
    {
      name: "Certifications",
      value: 0,
      type: "crt",
      remove: 0,
    },
    {
      name: "Executive Type",
      value: 0,
      type: "et",
      remove: 0,
    },
    {
      name: "Languages",
      value: 0,
      type: "lng",
      remove: 0,
    },
  ]);

  useEffect(() => {
    if (jobSlug !== undefined) {
      fetchMatchingCandidate();
    }
  }, [jobSlug]);

  const updateEachFilterValue = useCallback(() => {
    filterArray.map((item) => {
      if (item.type === "jt") {
        return setJobScore(item.value);
      } else if (item.type === "crt") {
        return setCScore(item.value);
      } else if (item.type === "edu") {
        return setEduScore(item.value);
      } else if (item.type === "et") {
        return setExScore(item.value);
      } else if (item.type === "in") {
        return setIndustryScore(item.value);
      } else if (item.type === "lng") {
        return setLngScore(item.value);
      } else if (item.type === "ml") {
        return setMlScore(item.value);
      } else if (item.type === "sk") {
        return setSkillScore(item.value);
      } else {
        return true;
      }
    });
  }, [filterArray]);

  useEffect(() => {
    updateEachFilterValue();
  }, [filterArray, updateEachFilterValue]);

  const fetchMatchingCandidate = () => {
    setIsLoading(true);
    Promise.all([
      getMatchingCandidateV2(
        jobSlug,
        user_slug,
        jobScore,
        eduScore,
        skillScore,
        industryScore,
        lngScore,
        mlScore,
        cScore,
        exScore
      ),
    ])
      .then(async ([data]) => {
        if (data?.data?.matches) {
          await setDataArray(data?.data?.matches);
          await setFilterData(data?.data?.filters_has_data);

          let newFilterArray = filterArray;

          await newFilterArray.map((item, index) => {
            if (item.type === "jt") {
              if (
                data?.data?.filters_has_data?.JobTitlesHasData ||
                data?.data?.filters_has_data?.JobTitles !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.JobTitles * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "crt") {
              if (
                data?.data?.filters_has_data?.CertificationsHasData ||
                data?.data?.filters_has_data?.Certifications !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.Certifications * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "edu") {
              if (
                data?.data?.filters_has_data?.EducationHasData ||
                data?.data?.filters_has_data?.Education !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.Education * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "et") {
              if (data?.data?.filters_has_data?.ExecutiveTypeHasData) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.ExecutiveType * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "in") {
              if (
                data?.data?.filters_has_data?.IndustriesHasData ||
                data?.data?.filters_has_data?.Industries !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.Industries * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "lng") {
              if (
                data?.data?.filters_has_data?.LanguagesHasData ||
                data?.data?.filters_has_data?.Languages !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.Languages * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "ml") {
              if (
                data?.data?.filters_has_data?.ManagementLevelHasData ||
                data?.data?.filters_has_data?.ManagementLevel !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.ManagementLevel * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else if (item.type === "sk") {
              if (
                data?.data?.filters_has_data?.SkillsHasData ||
                data?.data?.filters_has_data?.Skills !== 0
              ) {
                return (
                  (newFilterArray[index].value = parseInt(
                    data?.data?.filters_has_data?.Skills * 100
                  )),
                  (newFilterArray[index].remove = 0)
                );
              } else {
                return (newFilterArray[index].remove = 1);
              }
            } else {
              return true;
            }
          });

          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const updateValue = useCallback(
    async (val, type) => {
      var position = 0;
      var newFilter = [];

      await filterArray.map((item, index) => {
        if (item.type === type) {
          if (item.value < val.target.value) {
            position = true;
          } else {
            position = false;
          }
          return (newFilter[index] = { ...item, value: val.target.value });
        } else {
          return (newFilter[index] = item);
        }
      });

      await newFilter.map((item, index) => {
        if (item.type !== type) {
          if (position) {
            if (item.value > 0) {
              return (newFilter[index] = {
                ...item,
                value: item.value - 1,
              });
            } else {
              return false;
            }
          } else if (item.value < 100) {
            return (newFilter[index] = {
              ...item,
              value: item.value + 1,
            });
          } else {
            return false;
          }
        } else {
          return item;
        }
      });

      await setFilterArray(newFilter);
    },
    [filterArray]
  );

  const displayFilters = useCallback(() => {
    return (
      <div className="d-flex flex-wrap">
        {filterArray?.map((item, index) => {
          return (
            <>
              {!item?.remove ? (
                <div className="col-2 p-3" key={index.toString()}>
                  <label
                    for="customRange1"
                    className="form-label"
                    style={{ fontSize: 11 }}
                  >
                    {item?.name} ({item?.value}%)
                  </label>
                  <input
                    type="range"
                    className="form-range"
                    id="e"
                    min="0"
                    max="100"
                    step="1"
                    onChange={(e) => updateValue(e, item.type)}
                    defaultValue={item.value}
                    value={item.value}
                  />
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    );
  }, [filterArray, updateValue]);

  const updateProposeFor = (autoId, candidateId) => {
    Promise.all([getUpdateProposeFor(jobSlug, candidateId, autoId, user_slug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          setDataArray(
            dataArray.map((item) =>
              item?.profile?.user_id === candidateId
                ? autoId === null
                  ? { ...item, propose_for: data?.data?.auto_id }
                  : { ...item, propose_for: null }
                : item
            )
          );

          triggerUpdateProposeFor(true);
        }
      })
      .catch(() => {});
  };

  const updatePropose = (autoId, candidateId) => {
    updateProposeFor(autoId, candidateId);
  };

  return (
    <div className="d-block">
      <div className="alert alert-primary">
        <span>
          Adjust the importance of each category here. This will affect how each
          match is scored.
        </span>
      </div>
      <div className="bg-white bg-gradient rounded d-flex justify-content-between mb-2 align-items-center">
        {filterData ? (
          <>
            {displayFilters()}

            <div className="p-3">
              <Button
                variant="primary"
                size={"sm"}
                onClick={() => fetchMatchingCandidate()}
              >
                Update
              </Button>
            </div>
          </>
        ) : null}
      </div>
      <Table responsive="xl" className="bg-white">
        <thead className="bg-secondary">
          <tr className="fs-6 fw-bold text-light">
            <th>Shortlisted</th>
            <th className="p-3">Score</th>
            <th>Candidate</th>
            <th>Role</th>
            <th>Location</th>
            <th>Availability</th>
            <th>Action</th>
          </tr>
        </thead>
        {isLoading ? (
          <tbody className="fs-6">
            <tr>
              <td
                className="align-items-center justify-content-center align-item-center"
                colSpan={"7"}
              >
                Loading...
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="fs-6">
            {dataArray?.map((item, index) => {
              if (item?.profile) {
                const mailTo = `mailto:${item?.profile?.user_email}?subject=${
                  jobDetails.job_title
                }&body=Hi ${
                  item?.profile?.first_name
                }%0D%0A %0D%0AWe have identified an opportunity I feel could be a good fit for you.  Please review the details below and let me know if you are interested.%0D%0A %0D%0AJob Title: ${
                  jobDetails.job_title
                }%0D%0A %0D%0AOverview: ${jobDetails.job_description_raw.replace(
                  /<br\s*\/?>/gm,
                  "%0A"
                )}`;

                return (
                  <tr key={index.toString()}>
                    <td className="align-items-center justify-content-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        checked={item?.propose_for !== null ? true : false}
                        onChange={() =>
                          updatePropose(
                            item?.propose_for,
                            item?.profile?.user_id
                          )
                        }
                      />
                    </td>
                    <td className="col">
                      <span className="lead fw-bold">{item?.score}</span>
                    </td>
                    <td className="col">
                      <div className="d-flex">
                        <ProfileImageMd
                          imgSrc={item?.profile?.profile_image_path}
                        />
                        <div className="ms-3">
                          <div>
                            <span className="fw-bold">
                              {item?.profile?.r_name}
                            </span>
                          </div>
                          <div>
                            <Link
                              to="#"
                              onClick={(e) => {
                                window.location.href = mailTo;
                                e.preventDefault();
                              }}
                            >
                              <span
                                className="fw-normal text-primary"
                                title={item?.profile?.user_email}
                              >
                                {item?.profile?.user_email?.length > 30
                                  ? item?.profile?.user_email.substr(0, 30) +
                                    "..."
                                  : item?.profile?.user_email}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="col">
                      <span title={item?.profile?.user_profile_role}>
                        {item?.profile?.user_profile_role?.length > 50
                          ? item?.profile?.user_profile_role.substr(0, 50) +
                            "..."
                          : item?.profile?.user_profile_role}
                      </span>
                    </td>

                    <td className="col" style={{ width: 150 }}>
                      <span>
                        {item?.profile?.city_name !== null
                          ? item?.profile?.city_name + ", "
                          : ""}
                        {item?.profile?.state_name !== null
                          ? item?.profile?.state_name + ", "
                          : ""}
                        {item?.profile?.country_name}
                      </span>
                    </td>
                    <td className="col">
                      <div className="progress">
                        {item?.profile?.availability >= 20 &&
                        item?.profile?.availability <= 40 ? (
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width: `${
                                (item?.profile?.availability * 100) / 40
                              }%`,
                            }}
                            aria-valuenow={item?.profile?.availability}
                            aria-valuemin="0"
                            aria-valuemax="40"
                          />
                        ) : (
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{
                              width: `${
                                (item?.profile?.availability * 100) / 40
                              }%`,
                            }}
                            aria-valuenow={item?.profile?.availability}
                            aria-valuemin="0"
                            aria-valuemax="40"
                          />
                        )}
                      </div>
                      <div className="">
                        <span>{item?.profile?.availability}hrs/week</span>
                      </div>
                    </td>
                    <td className="col">
                      <Link
                        to={`/resources/details/${item?.profile?.user_slug}`}
                        target="_blank"
                      >
                        <Button variant="warning" size={"sm"}>
                          View Profile
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              } else {
                return false;
              }
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default AIMatchingUI;
