import React, { useCallback, useEffect, useState } from "react";
import { FIRM_IMAGE_BASE } from "../../config/env";
import getJobDetails from "../../apis/getJobDetails";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProposedCandidate from "./ProposedCandidate";
import AIMatchingUI from "./AIMatchingUI";
import BlockHeader from "../Headers/BlockHeader";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import InputLebelComponent from "../InputLebel/InputLebelComponent";
import { AlertDanger } from "../Alerts/Alert";
import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";
import { useAuthContext } from "../../context/AuthContext";
import removeJob from "../../apis/removeJob";

const JobDetails = (props) => {
  const MySwal = swalWithBootstrapButtons(Swal);
  const { userDetails } = useAuthContext();
  const currentUser = JSON.parse(userDetails);
  const user_slug = currentUser.user_slug;
  const user_id = JSON.parse(userDetails).user_id;
  const user_firm = currentUser?.firm_details?.firm_id;

  const { jobSlug } = props;
  const [isJobDetailsLoading, setIsJobDetailsLoading] = useState(true);
  const [jobArray, setJobArray] = useState(false);
  const [jobFirmAccess, setJobFirmAccess] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [key, setKey] = useState("candidates");
  const [apiStatusMessage, setApiStatusMessage] = useState(
    "Nothing to display!"
  );

  const [removeNote, setRemoveNote] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [buttonText, setButtonText] = useState("Remove");
  const [selectedText, setSelectedText] = useState("");
  const [selectedHeader, setSelectedHeader] = useState("");
  const [updateProposeFor, setUpdateProposeFor] = useState(false);

  useEffect(() => {
    if (user_firm !== undefined) {
      getJob();
    }
  }, [user_firm]);

  const getJob = () => {
    Promise.all([getJobDetails(jobSlug, user_firm)])
      .then(async ([data]) => {
        if (data?.data?.job_details) {
          await setIsJobDetailsLoading(false);
          await setJobArray(data.data.job_details);
          await setJobFirmAccess(
            data.data.job_details.job_firm_access.split(",")
          );
        } else {
          setIsJobDetailsLoading(false);
          setApiStatusMessage(data?.data.message);
        }
      })
      .catch((err) => {
        setIsJobDetailsLoading(false);
      });
  };

  const closeJobRemoveModal = () => {
    setRemoveNote("");
    setIsRemoveModalOpen(false);
    setErrorMessage("");
    setHasSubmitError(false);
    setIsButtonDisabled(false);
    setButtonText("Remove");
  };

  const handleRemoveNoteChange = (e) => {
    setRemoveNote(e.target.value);
  };

  const processRemove = () => {
    setIsButtonDisabled(true);

    let isInvalid = 0;
    let errMessage = [];

    if (removeNote.trim().length === 0) {
      isInvalid = 1;
      errMessage.push("Enter a reason");
    }

    if (isInvalid === 1) {
      setErrorMessage(errMessage);
      setHasSubmitError(true);
      setIsButtonDisabled(false);
      setButtonText("Remove");
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    setIsRemoveModalOpen(false);
    setErrorMessage("");
    setHasSubmitError(false);
    setIsButtonDisabled(false);
    setButtonText("Remove");

    let formData = {
      user_slug: user_slug,
      job_slug: jobArray.job_slug,
      reason: removeNote,
    };

    try {
      removeJob(formData).then(async (data) => {
        if (data?.data) {
          if (data.data.status === 1) {
            window.location.replace("/job/latest-jobs");
          } else if (data.data.status === 0) {
            await MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>{data.data.message}</i>,
              icon: "danger",
            });

            window.location.reload();
          } else {
            await MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>{data.data.status}</i>,
              icon: "danger",
            });

            window.location.reload();
          }
        } else {
          await MySwal.fire({
            title: <strong>Error</strong>,
            html: <i>{data.data.message}</i>,
            icon: "danger",
          });

          window.location.reload();
        }
      });
    } catch (error) {
      MySwal.fire({
        title: <strong>Success</strong>,
        html: <i>Something wrong happened!</i>,
        icon: "danger",
      });
    }
  };

  const displayMainContent = () => {
    return (
      <>
        {isJobDetailsLoading ? (
          <div className="justify-content-center align-items-center d-flex">
            <Spinner animation="grow" role="status" />
          </div>
        ) : (
          <>{jobArray ? displayJobDetailsBlock() : displayNoDataBlock()}</>
        )}
      </>
    );
  };

  const displayNoDataBlock = () => {
    return (
      <Alert key={"info"} variant={"info"}>
        {apiStatusMessage}
      </Alert>
    );
  };

  const displayHeaderBlock = () => {
    return (
      <div className="card-custom mb-4">
        <div className="card-body">
          <div className="d-block mb-3">
            <span className="text-info-custom fs-4 fw-bold">
              {jobArray.job_title}
            </span>
            {jobArray.employer_name !== null &&
            jobArray.employer_name !== "" ? (
              <>
                <br />
                <span className="fw-bold">{jobArray.employer_name}</span>
              </>
            ) : null}
            <div className="d-dlock">
              <span className="text-secondary">Job ID :</span>{" "}
              <span className="text-dark fw-bold">{jobArray.job_id}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="d-dlock">
                <span className="text-secondary">Posted by :</span>{" "}
                <span className="text-info-custom">
                  {jobArray.creator_name}
                </span>
              </div>
              <div className="d-block">
                <span className="text-secondary">Posted on :</span>{" "}
                <span className="text-dark">
                  {moment(jobArray.created_on).format("MMMM Do, YYYY")}
                </span>
              </div>

              <div className="d-block">
                <span className="text-secondary">Closing on :</span>{" "}
                <span className="text-dark">
                  {jobArray.closing_date !== null &&
                  jobArray.closing_date !== ""
                    ? moment(jobArray.closing_date).format("MMMM Do, YYYY")
                    : "Unknown"}
                </span>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              {displayFirm(jobArray.firm_logo)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displayDiscription = () => {
    if (jobArray.job_description !== "") {
      return (
        <div className="d-block">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Job Overview:</span>
          </div>
          <div className="d-block">
            {checkDescriptionlength(jobArray.job_description, "Job Overview")}
          </div>
        </div>
      );
    }
  };

  const checkDescriptionlength = (details, header) => {
    if (details !== null && details !== "" && details !== undefined) {
      if (details.length > 200) {
        return (
          <>
            <span>{details.substr(0, 199) + "..."}</span>{" "}
            <span
              className="text-info-custom cursor-pointer fw-medium-custom"
              onClick={() => openModal(details, header)}
            >
              Read more
            </span>
          </>
        );
      } else {
        return <span>{details}</span>;
      }
    }
  };

  const openModal = (message, header) => {
    setSelectedHeader(header);
    setSelectedText(message);
    setIsOpen(true);
  };

  const displayDescriptionModal = () => {
    return (
      <Modal
        show={isOpen}
        scrollable={true}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="d-block">
            <div className="d-block">
              <span className="fs-5 fw-bold-custom">{selectedHeader}</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <span>{selectedText}</span>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            size={"sm"}
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayRemoveModal = () => {
    return (
      <Modal
        show={isRemoveModalOpen}
        scrollable={true}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        top
      >
        <Modal.Header>
          <div className="d-block">
            <div className="d-block">
              <span className="fs-5 fw-bold-custom">
                Are you sure that you want to remove this job posting?
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-block">
            <div className="form-input-holder">
              <InputLebelComponent title="Add a reason" />
              <div className="d-block">
                <input
                  type="text"
                  className="form-control"
                  id="role-title"
                  placeholder="Enter your reason"
                  onChange={handleRemoveNoteChange}
                  value={removeNote}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          {displayStatusMessage()}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-dark"
            size={"sm"}
            onClick={() => closeJobRemoveModal()}
            disabled={isButtonDisabled}
          >
            Close
          </Button>
          <Button
            variant="danger"
            size={"sm"}
            onClick={() => processRemove(false)}
            disabled={isButtonDisabled}
          >
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayStatusMessage = () => {
    return <div className="d-block my-4">{displayErrorMessage()}</div>;
  };

  const displayErrorMessage = () => {
    if (hasSubmitError) {
      return <AlertDanger title={"Oops"} message={errorMessage} />;
    }
  };

  const displaySkills = () => {
    if (
      jobArray.required_skill_set !== "" &&
      jobArray.required_skill_set !== null
    ) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Skills Required:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.required_skill_set}</span>
          </div>
        </div>
      );
    }
  };

  const displayExperience = () => {
    if (
      jobArray.experience_required !== "" &&
      jobArray.experience_required !== null
    ) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Experience Required:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.experience_required}</span>
          </div>
        </div>
      );
    }
  };

  const displayJobType = () => {
    return (
      <div className="d-block mt-3">
        <div className="d-block mb-2">
          <span className="fs-5 fw-bold">Job Type:</span>
        </div>
        <div className="d-block">
          <Button variant="warning rounded-lg" size={"sm"}>
            {jobArray.job_type === "1" ? "Permanent" : "Contract"}
          </Button>
        </div>
      </div>
    );
  };

  const displayContractLength = () => {
    if (jobArray.contract_length !== "" && jobArray.contract_length !== null) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Contract Length:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.contract_length}</span>
          </div>
        </div>
      );
    }
  };

  const displayCompensation = () => {
    if (jobArray.compensation !== "" && jobArray.compensation !== null) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Compensation:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.compensation}</span>
          </div>
        </div>
      );
    }
  };

  const displayLocationRequirement = () => {
    if (
      jobArray.location_requirement !== "" &&
      jobArray.location_requirement !== null
    ) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Location Requirement:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.location_requirement}</span>
          </div>
        </div>
      );
    }
  };

  const displayBenefits = () => {
    if (jobArray.benefits !== "" && jobArray.benefits !== null) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Benefits:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.benefits}</span>
          </div>
        </div>
      );
    }
  };

  const displayWorkingHours = () => {
    if (jobArray.working_hours !== "" && jobArray.working_hours !== null) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Working Hours:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.working_hours}</span>
          </div>
        </div>
      );
    }
  };

  const displayJobRequirement = () => {
    if (
      jobArray.job_requirements !== "" &&
      jobArray.job_requirements !== null
    ) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Job Requirements:</span>
          </div>

          <div className="d-block">
            {checkDescriptionlength(
              jobArray.job_requirements,
              "Job Requirements"
            )}
          </div>
        </div>
      );
    }
  };

  const displayJobFile = () => {
    if (jobArray.job_file_path !== "" && jobArray.job_file_path !== null) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Job File:</span>
          </div>

          <div className="d-block">
            <a
              href={`${FIRM_IMAGE_BASE + jobArray.job_file_path}`}
              style={{ width: 100 }}
            >
              <Button variant="success" size="sm">
                Download
              </Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const displayApplicationDetails = () => {
    if (
      jobArray.application_details !== "" &&
      jobArray.application_details !== null
    ) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Application Details:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.application_details}</span>
          </div>
        </div>
      );
    }
  };

  const displayEmployerDetails = () => {
    if (jobArray.employer_name !== "" && jobArray.employer_name !== null) {
      return (
        <div className="d-block mt-3">
          {jobArray.employer_description !== "" &&
          jobArray.employer_description !== null ? (
            <div className="d-block">
              <span className="text-dark">
                <b>About:</b> {jobArray.employer_description}
              </span>
            </div>
          ) : null}
          {jobArray.employer_contact !== "" &&
          jobArray.employer_contact !== null ? (
            <div className="d-block">
              <span className="text-dark">
                <b>Contact:</b> {jobArray.employer_contact}
              </span>
            </div>
          ) : null}

          {jobArray.employer_website !== "" &&
          jobArray.employer_website !== null ? (
            <div className="d-block">
              <span className="text-dark">
                <b>Website:</b> {jobArray.employer_website}
              </span>
            </div>
          ) : null}
        </div>
      );
    }
  };

  const displayOtherRequirement = () => {
    if (jobArray.other !== "" && jobArray.other !== null) {
      return (
        <div className="d-block mt-3">
          <div className="d-block mb-2">
            <span className="fs-5 fw-bold">Other Requirement:</span>
          </div>
          <div className="d-block">
            <span className="text-dark">{jobArray.other}</span>
          </div>
        </div>
      );
    }
  };

  const displayOverviewBlock = () => {
    return (
      <div className="card-custom">
        <div className="card-body">
          {displayDiscription()}
          {displaySkills()}
          {displayExperience()}
          {displayJobRequirement()}
          {displayJobFile()}
          {displayJobType()}
          {displayContractLength()}
          {displayCompensation()}
          {displayBenefits()}
          {displayWorkingHours()}
          {displayApplicationDetails()}
          {displayLocationRequirement()}
          {displayEmployerDetails()}
          {displayOtherRequirement()}
        </div>
      </div>
    );
  };

  const triggerUpdateProposeFor = (status) => {
    setUpdateProposeFor(status);
  };

  const displayProposeFor = useCallback(() => {
    return (
      <ProposedCandidate
        jobSlug={jobSlug}
        user_slug={user_slug}
        hasUpdate={updateProposeFor}
        triggerUpdateProposeFor={triggerUpdateProposeFor}
      />
    );
  }, [updateProposeFor, jobSlug, user_slug, triggerUpdateProposeFor]);

  const displayAI = useCallback(() => {
    return (
      <AIMatchingUI
        jobSlug={jobSlug}
        user_slug={user_slug}
        triggerUpdateProposeFor={triggerUpdateProposeFor}
        jobDetails={jobArray}
      />
    );
  }, [triggerUpdateProposeFor]);

  const displayTabSection = () => {
    return (
      <div className="card-custom my-4 bg-light">
        <div className="card-body">
          {jobArray.firm_id === user_firm ||
          jobFirmAccess.includes(user_firm) ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="candidates" title="Candidates">
                {displayProposeFor()}
              </Tab>
              {jobArray.requirement_id !== null &&
              jobArray.is_submitted !== "0" ? (
                <Tab eventKey="aiMatchingUI" title="AI Matching">
                  {displayAI()}
                </Tab>
              ) : null}
            </Tabs>
          ) : (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="candidates" title="Candidates">
                <ProposedCandidate jobSlug={jobSlug} user_slug={user_slug} />
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
    );
  };

  const displayJobDetailsBlock = () => {
    return (
      <>
        {displayHeaderBlock()}
        {displayOverviewBlock()}
        {displayDescriptionModal()}
        {displayRemoveModal()}
        {displayTabSection()}
      </>
    );
  };

  const displayFirm = (logo_path) => {
    return (
      <div
        className="firm-logo-sm-custom"
        style={{
          backgroundImage: `url("${FIRM_IMAGE_BASE + logo_path}")`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    );
  };

  const openRemoveJobModal = async () => {
    await setIsRemoveModalOpen(true);
  };

  return (
    <div className="d-block">
      <div className="d-block">
        <BlockHeader
          title={"Job Details"}
          subText={"Check the job details and matching candidate profiles"}
        />
      </div>
      <div className="d-flex justify-content-end mb-2">
        <Link to={`/job/latest-jobs`}>
          <Button variant="success" size="sm">
            Back to job listing
          </Button>
        </Link>
      </div>

      {displayMainContent()}

      {jobArray && user_id === jobArray.created_by ? (
        <div className="d-flex justify-content-center my-2">
          <Link to={`/job/edit/${jobArray.job_slug}`}>
            <Button variant="success" size="sm" className="ms-3">
              Edit Job
            </Button>
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-3"
            onClick={() => openRemoveJobModal()}
          >
            Remove Job
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default JobDetails;
