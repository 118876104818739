import axios from "axios";

//IMPORT API ROUTE URL
import { CREATE_JOB_VIA_CONTENT_ROUTE } from "./apiRoutes";

const createJobViaContentEntry = (data) => {
  var postData = new FormData();

  postData.append("user_slug", data.user_slug);
  postData.append("content", data.content);

  return axios
    .post(CREATE_JOB_VIA_CONTENT_ROUTE, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default createJobViaContentEntry;
