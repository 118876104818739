import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import InputLebelComponent from "../../InputLebel/InputLebelComponent";
import removeCertificate from "../../../apis/removeCertificate";
import addCertificate from "../../../apis/addCertificate";
import { useAuthContext } from "../../../context/AuthContext";

const CertificationSet = (props) => {
  const { userDetails } = useAuthContext();
  const user_primary_role = JSON.parse(userDetails).user_primary_role;

  const MySwal = swalWithBootstrapButtons(Swal);
  const { resourceSlug, certificationDetails } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [certificateSet, setCertificateSet] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [crtText, setCrtText] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => {
    setShow(false);
    setShowEdit(true);
  };

  useEffect(() => {
    setIsLoading(false);
    setCertificateSet(certificationDetails);
  }, [certificationDetails]);

  const displayBlockContent = () => {
    if (isLoading) {
      return <LoadingPageSm />;
    } else {
      return renderCertificationSet();
    }
  };

  const renderCertificationSet = () => {
    if (certificateSet !== null && certificateSet) {
      return (
        <div>
          <div className="skill-holder flex-column mb-3">
            {certificateSet.map((item, key) => {
              return displayCertificationItem(item, key);
            })}
          </div>
          <div className="d-flex justify-content-end">
            <Button variant="primary" size="sm" onClick={() => handleShow()}>
              View Certifications
            </Button>
          </div>

          {displayCertificateModalContent()}
        </div>
      );
    } else {
      return (
        <div>
          <div className="mb-3">
            <span className="text-muted">Nothing to display here!</span>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleShowEdit()}
            >
              Update
            </Button>
          </div>
        </div>
      );
    }
  };

  const displayCertificationItem = (item) => {
    return (
      <div
        className="skill-wrapper-dark my-1 me-1 mb-2"
        key={item.certification_id.toString()}
      >
        {item.certification_title.length > 33 ? (
          <span title={item.certification_title.replace(/[^a-zA-Z0-9 ]/g, "")}>
            {item.certification_title
              .substr(0, 33)
              .replace(/[^a-zA-Z0-9 ]/g, "") + "..."}
          </span>
        ) : (
          <span title={item.certification_title}>
            {item.certification_title.replace(/[^a-zA-Z0-9 ]/g, "")}
          </span>
        )}
      </div>
    );
  };

  const displayCertificateEditModalContent = () => {
    return (
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-block">
            <span className="fw-bold-custom">Update Certifications</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {displayNewAdd()}
          <hr />
          {displayAllCertificatesEditable()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseEdit} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleTextChange = (e) => {
    setCrtText(e.target.value);
  };

  const handleAddNewCrt = () => {
    if (crtText.trim().length > 0) {
      setIsAdding(true);
      processAddNewCrt();
    } else {
      toast.error("Please enter certification name", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const processAddNewCrt = () => {
    setIsAdding(true);
    Promise.all([addCertificate(resourceSlug, crtText)])
      .then(([data]) => {
        if (data?.data?.status) {
          setCrtText("");

          if (certificateSet.length > 0) {
            setCertificateSet((oldArray) => [...oldArray, data.data.crt]);
          } else {
            setCertificateSet([data.data.crt]);
          }

          toast.success(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        }

        setIsAdding(false);
      })
      .catch((err) => {
        setIsAdding(false);
        console.log(err);
      });
  };

  const displayNewAdd = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="col-6">
          <div className="form-input-holder">
            <InputLebelComponent title="Certification Name" />
            <div className="d-block">
              <input
                type="email"
                className="form-control"
                id="new-skill"
                placeholder="Enter new certification name"
                onChange={handleTextChange}
                value={crtText}
                autoComplete="off"
                disabled={isAdding}
              />
            </div>
          </div>
        </div>
        <div className="col-6 ps-3">
          <Button
            variant="primary"
            onClick={handleAddNewCrt}
            size="lg"
            disabled={isAdding}
          >
            Add Certification
          </Button>
        </div>
      </div>
    );
  };

  const displayCertificateModalContent = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header>
          <div className="d-block">
            <span className="fw-bold-custom">My Certifications</span>
          </div>
        </Modal.Header>
        <Modal.Body>{displayAllCertificates()}</Modal.Body>
        <Modal.Footer>
          <Button
            className="me-3"
            variant="success"
            size="sm"
            onClick={() => handleShowEdit()}
          >
            Update
          </Button>

          <Button variant="primary" onClick={handleClose} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayAllCertificates = () => {
    if (certificateSet !== null && certificateSet) {
      return (
        <>
          <p className="fw-bold-custom">Current Certifications</p>
          <div className="d-flex flex-wrap">
            {certificateSet.map((item, key) => {
              return (
                <div
                  className="skill-wrapper-dark my-1 me-1 mb-2"
                  key={item.certification_id.toString()}
                >
                  <span>
                    {item.certification_title.replace(/[^a-zA-Z0-9 ]/g, "")}
                  </span>
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return <span className="text-muted">Nothing to display here!</span>;
    }
  };

  const remove = (id, name) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "that you want to remove " + name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "var(--black)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        processRemove(id);
      }
    });
  };

  const processRemove = (id) => {
    setIsAdding(true);
    Promise.all([removeCertificate(resourceSlug, id)])
      .then(([data]) => {
        if (data?.data?.status) {
          if (certificateSet.length === 1) {
            setCertificateSet(false);
          } else {
            setCertificateSet((current) =>
              current.filter((crt) => crt.certification_id !== id)
            );
          }

          toast.success(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        }

        setIsAdding(false);
      })
      .catch((err) => {
        setIsAdding(false);
        console.log(err);
      });
  };

  const displayAllCertificatesEditable = () => {
    if (certificateSet !== null && certificateSet) {
      return (
        <>
          <p className="fw-bold-custom">Current Certifications</p>
          <div className="d-flex flex-wrap">
            {certificateSet.map((item, key) => {
              return (
                <button
                  className="btn my-1 me-1 mb-2 d-flex btn-dark"
                  key={item.certification_id.toString()}
                  disabled={isAdding}
                  onClick={() =>
                    remove(
                      item.certification_id,
                      item.certification_title.replace(/[^a-zA-Z0-9 ]/g, "")
                    )
                  }
                >
                  <span className="">
                    {item.certification_title.replace(/[^a-zA-Z0-9 ]/g, "")}
                  </span>
                  <div className="ms-2 pe-none cursor-pointer">
                    <BsTrash className={`text-light`} />
                  </div>
                </button>
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <>
          <p className="fw-bold-custom">Current Certifications</p>
          <span className="text-muted">Nothing to display here!</span>
        </>
      );
    }
  };

  return (
    <div className="col-12 mb-3">
      <div className="card-custom h-100">
        <div className="card-body ">
          <div className="d-block mb-3">
            <span className="fw-bold">My Certifications</span>
          </div>
          {displayBlockContent()}
        </div>
      </div>
      {displayCertificateEditModalContent()}
    </div>
  );
};

export default CertificationSet;
