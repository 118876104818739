import React from "react";
import AccountLoginDetailsForm from "./AccountLoginDetailsForm";
import ProfileBasicForm from "./ProfileBasicForm";
import ProfileContactForm from "./ProfileContactForm";
import BlockHeader from "../../Headers/BlockHeader";
import ProfilePasswordResetForm from "../../MyProfile/ProfilePasswordResetForm";

const EditResourceManagerFormBlock = (props) => {
  const { resourceManagerDetails, resourceManagerSlug } = props;

  return (
    <>
      <BlockHeader
        title={"Edit Profile Details"}
        subText={
          "Please check the below informations carefully and then update"
        }
        borderBottom={true}
      />

      <div className="row">
        <div className="col-12 col-lg-8 col-xl-8 col-xxl-8">
          <div className="d-block mb-4">
            <AccountLoginDetailsForm
              resourceManagerDetails={resourceManagerDetails.login_details}
              resourceManagerSlug={resourceManagerSlug}
            />
          </div>
          <div className="d-block mb-4">
            <ProfileBasicForm
              resourceManagerDetails={resourceManagerDetails.profile_details}
              resourceManagerSlug={resourceManagerSlug}
            />
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-4 col-xxl-4">
          <div className="d-block mb-4">
            <ProfileContactForm
              resourceManagerDetails={resourceManagerDetails.contact_details}
              resourceManagerSlug={resourceManagerSlug}
            />
          </div>
          <div className="d-block">
            <ProfilePasswordResetForm userSlug={resourceManagerSlug} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditResourceManagerFormBlock;
