import React, { useEffect, useState } from "react";
import getVLinkDetails from "../../apis/getVLinkDetails";
import LoadingPageSm from "../../components/CommonComponent/LoadingPageSm";

const VerifyAccount = (props) => {
  const { verifylink } = props.match.params;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (verifylink) {
      getLink();
    }
  }, [verifylink]);

  const getLink = () => {
    Promise.all([getVLinkDetails(verifylink)])
      .then(async ([data]) => {
        if (data?.data?.message) {
          setIsLoading(false);
          setData(data?.data);
        } else {
          setIsLoading(false);
          setData("Something went wrong. Please try again later.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setData("Something went wrong. Please try again later.");
      });
  };

  const displayHeader = () => {
    if (data.status === 1) {
      return <h1 className="text-success">Success</h1>;
    } else {
      return <h2 className="text-danger">Error</h2>;
    }
  };

  return (
    <div className="container h-100 mt-5">
      <div className="d-flex justify-content-center align-items-center text-center">
        {isLoading ? (
          <LoadingPageSm />
        ) : (
          <div>
            {displayHeader()}
            <h6 className="text-muted">{data.message}</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyAccount;
