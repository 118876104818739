import React, { useEffect, useState } from "react";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import { Button, Modal } from "react-bootstrap";
import InputLebelComponent from "../../InputLebel/InputLebelComponent";
import { BsPlus, BsTrash } from "react-icons/bs";
import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";
import { toast } from "react-toastify";
import removeLanguageToUser from "../../../apis/removeLanguageToUser";
import getLanguagesBySearchText from "../../../apis/getLanguagesBySearchText";
import addLanguageToUser from "../../../apis/addLanguageToUser";
import { useAuthContext } from "../../../context/AuthContext";

const Language = (props) => {
  const { userDetails } = useAuthContext();
  const user_primary_role = JSON.parse(userDetails).user_primary_role;

  const MySwal = swalWithBootstrapButtons(Swal);
  const { languageDetails, displayView, resourceSlug, profileName } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState(false);
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [languageSearchText, setLanguageSearchText] = useState("");
  const [searchedLanguages, setSearchedLanguages] = useState(false);
  const [isLoadingLanguageSearch, setIsLoadingLanguageSearch] = useState(false);
  const [filterTextStatus, setFilterTextStatus] = useState(
    "Your search languages will be displayed here"
  );
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);

  useEffect(() => {
    setLang();
  }, [languageDetails]);

  const setLang = async () => {
    setIsLoading(false);
    setLanguage(languageDetails);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowEdit = () => {
    setShow(false);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    //window.location.reload();
  };

  const displayBlockContent = () => {
    if (isLoading) {
      return <LoadingPageSm />;
    } else {
      return renderLanguage();
    }
  };

  const displayLanguageModalContent = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header>
          <div className="d-block">
            <span className="fw-bold-custom">My Languages</span>
          </div>
        </Modal.Header>
        <Modal.Body>{renderLanguage()}</Modal.Body>
        <Modal.Footer>
          {displayView !== "client" && user_primary_role !== "3" ? (
            <Button
              className="me-3"
              variant="success"
              size="sm"
              onClick={() => handleShowEdit()}
            >
              Update
            </Button>
          ) : null}

          <Button variant="primary" onClick={handleClose} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayLanguageEditModalContent = () => {
    return (
      <Modal
        fullscreen
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="fullscreen"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-block">
            <span className="fw-bold-custom">
              Edit Languages for {profileName}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {displayNewLanguageAdd()}
          {displayAllRawLanguages()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseEditModal} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayAllRawLanguages = () => {
    return renderRawLanguageSet();
  };

  const displayNewLanguageAdd = () => {
    return (
      <div className="d-block">
        <div className="form-input-holder">
          <InputLebelComponent title="Search Languages" />
          <div className="d-block">
            <input
              type="email"
              className="form-control"
              id="new-language"
              placeholder="Search language to add"
              onChange={handleTextChange}
              value={languageSearchText}
              autoComplete="off"
            />
          </div>
          <div className="border-dark p-2 rounded my-2 bg-light">
            {displaySearchContent()}
          </div>
        </div>
      </div>
    );
  };

  const displaySearchContent = () => {
    if (isLoadingLanguageSearch) {
      return (
        <div className="mt-2">
          <LoadingPageSm />
        </div>
      );
    } else {
      if (!searchedLanguages) {
        return <span>{filterTextStatus}</span>;
      } else {
        return displaySearchedLanguageItems();
      }
    }
  };

  const displaySearchedLanguageItems = () => {
    return (
      <>
        {searchedLanguages ? (
          <>
            <div className="my-2">
              <span>Click on (+) button to add new language</span>
            </div>

            <div className="d-block raw-language-holder">
              <div className="d-flex flex-wrap">
                {searchedLanguages.map((item, key) => {
                  return displaySearchLanguageItem(item, key);
                })}
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const displaySearchLanguageItem = (item) => {
    return (
      <button
        className="btn my-1 me-1 mb-2 d-flex btn-success"
        key={item.language_id.toString()}
        disabled={isAddingLanguage}
        onClick={() => addLanguage(item.language_id, { item })}
      >
        <span>{item.value}</span>
        <div className="ms-2 pe-none cursor-pointer">
          <BsPlus className={`text-light`} />
        </div>
      </button>
    );
  };

  const addLanguage = (id, item) => {
    setIsAddingLanguage(true);
    Promise.all([addLanguageToUser(resourceSlug, id)])
      .then(([data]) => {
        if (data?.data?.status) {
          setSearchedLanguages((current) =>
            current.filter((languages) => languages.language_id !== id)
          );

          setLanguage((oldArray) => [...oldArray, item.item]);

          toast.success(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        }

        setIsAddingLanguage(false);
      })
      .catch((err) => {
        setIsAddingLanguage(false);
        console.log(err);
      });
  };

  const renderRawLanguageSet = () => {
    return (
      <>
        <div className="d-block">
          <span className="fw-bold-custom">Current Languages</span>
        </div>
        <div className="d-block raw-language-holder">
          {language ? (
            <div className="d-flex flex-wrap">
              {language.map((item, key) => {
                return displayRawLanguageItem(item, key);
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const displayRawLanguageItem = (item) => {
    return (
      <button
        className="btn my-1 me-1 mb-2 d-flex btn-dark"
        key={item.toString()}
        disabled={isAddingLanguage}
        onClick={() => removeLanguage(item.language_id, item.value)}
      >
        <span className="">{item.value}</span>
        <div className="ms-2 pe-none cursor-pointer">
          <BsTrash className={`text-light`} />
        </div>
      </button>
    );
  };

  const removeLanguage = (id, name) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "that you want to remove " + name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "var(--black)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        processRemoveLanguage(id);
      }
    });
  };

  const processRemoveLanguage = (id) => {
    setIsAddingLanguage(true);
    Promise.all([removeLanguageToUser(resourceSlug, id)])
      .then(([data]) => {
        if (data?.data?.status) {
          setLanguage((current) =>
            current.filter((languages) => languages.language_id !== id)
          );

          toast.success(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
          });
        }

        setIsAddingLanguage(false);
      })
      .catch((err) => {
        setIsAddingLanguage(false);
        console.log(err);
      });
  };

  const renderLanguage = () => {
    if (language !== null && language) {
      return (
        <div className="d-flex flex-wrap">
          {language.map((item) => {
            return (
              <div
                className="skill-wrapper-dark my-1 me-1 mb-2"
                key={item.toString()}
              >
                <span>{item.value}</span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <span className="text-muted">Nothing to display here!</span>;
    }
  };

  const handleTextChange = (e) => {
    setLanguageSearchText(e.target.value);
  };

  useEffect(() => {
    if (languageSearchText.trim().length > 1) {
      const getData = setTimeout(() => {
        setIsLoadingLanguageSearch(true);
        getLanguageWithSearchText();
      }, 500);

      return () => clearTimeout(getData);
    } else {
      setFilterTextStatus("Your search languages will be displayed here");
      setSearchedLanguages(false);
    }
  }, [languageSearchText]);

  const getLanguageWithSearchText = () => {
    Promise.all([getLanguagesBySearchText(languageSearchText, resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          setSearchedLanguages(data?.data?.languages);
        } else {
          setSearchedLanguages(false);
          setFilterTextStatus(data?.data?.message);
        }

        setIsLoadingLanguageSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="col-12 mb-3">
      <div className="card-custom h-100">
        <div className="card-body ">
          <div className="d-block mb-3">
            <span className="fw-bold">Languages</span>
          </div>
          {displayBlockContent()}
          <div className="d-flex justify-content-end mt-2">
            <Button variant="primary" size="sm" onClick={() => handleShow()}>
              View Languages
            </Button>
          </div>
        </div>
      </div>
      {displayLanguageModalContent()}
      {displayLanguageEditModalContent()}
    </div>
  );
};

export default Language;
