import axios from "axios";

//IMPORT API ROUTE URL
import { CHANGE_FAVORITE_ROUTE } from "./apiRoutes";

const changeFavorite = (user_id, resource_id) => {
  var postData = new FormData();

  postData.append("user_id", user_id);
  postData.append("resource_id", resource_id);

  return axios
    .post(CHANGE_FAVORITE_ROUTE, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default changeFavorite;
