import axios from "axios";

//IMPORT API ROUTE URL
import { REMOVE_SKILL_ROUTE } from "./apiRoutes";

const removeSkillToUser = (resource_slug, skill_id) => {
  var postData = new FormData();

  postData.append("skill_id", skill_id);
  postData.append("resource_slug", resource_slug);

  return axios
    .post(REMOVE_SKILL_ROUTE, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default removeSkillToUser;
