import React from "react";
import TopProfileSection from "./TopProfileSection";

const FirmOwnerScreen = () => {
  return (
    <>
      <TopProfileSection />
    </>
  );
};

export default FirmOwnerScreen;
