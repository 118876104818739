import AccountLoginDetailsForm from "./AccountLoginDetailsForm";
import ProfileBasicForm from "./ProfileBasicForm";
import ProfileContactForm from "./ProfileContactForm";
import ResourceDocumentForm from "./ResourceDocumentForm";
import ResourceEducationBlock from "./ResourceEducationBlock";
import ResourceEmploymentBlock from "./ResourceEmploymentBlock";
import ResourceAvailabilityForm from "./ResourceAvailabilityForm";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";
import removeAccount from "../../../apis/removeAccount";

const EditResourceFormBlock = (props) => {
  const { resourceDetails, resourceSlug } = props;
  const { userDetails } = useAuthContext();
  const firmType = JSON.parse(userDetails).firm_details?.firm_type;
  const MySwal = swalWithBootstrapButtons(Swal);
  const user_slug = JSON.parse(userDetails).user_slug;

  const handleRemoveAccount = (resourceSlug) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "that you want to remove this account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "var(--black)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        removeResource(resourceSlug);
      }
    });
  };

  const removeResource = (slug) => {
    let formData = {
      user_slug: user_slug,
      resource_slug: slug,
    };

    try {
      removeAccount(formData).then(async (data) => {
        if (data?.data) {
          if (data.data.status === 1) {
            await MySwal.fire({
              title: <strong>Success</strong>,
              html: <i>{data.data.message}</i>,
              icon: "success",
            });

            window.location.href = "https://www.firmconnector.com/resources";
          } else if (data.data.status === 0) {
            await MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>{data.data.message}</i>,
              icon: "danger",
            });
          } else {
            await MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>{data.data.status}</i>,
              icon: "danger",
            });
          }
        } else {
          await MySwal.fire({
            title: <strong>Error</strong>,
            html: <i>{data.data.message}</i>,
            icon: "danger",
          });
        }
      });
    } catch (error) {
      MySwal.fire({
        title: <strong>Success</strong>,
        html: <i>Something wrong happened!</i>,
        icon: "danger",
      });
    }
  };

  return (
    <>
      <div className="d-flex mb-4">
        <div className="me-2">
          <Link
            to={
              firmType === "1"
                ? "/my-team/details/" + resourceSlug
                : "/resources/details/" + resourceSlug
            }
          >
            <Button variant={"secondary"} size="sm">
              Default View
            </Button>
          </Link>
        </div>
        <div className="me-2">
          <Link
            to={
              firmType === "1"
                ? "/my-team/details/" + resourceSlug + "?cv=true"
                : "/resources/details/" + resourceSlug + "?cv=true"
            }
          >
            <Button variant={"secondary"} size="sm" className="ms-2">
              Client View
            </Button>
          </Link>
        </div>
        <Button variant="primary" size="sm" className="ms-2">
          Edit Profile
        </Button>
      </div>
      <div className="d-flex row">
        <div className="col-12 col-lg-8 col-xl-8">
          <div className="d-block mb-4">
            <AccountLoginDetailsForm
              resourceDetails={resourceDetails}
              resourceSlug={resourceSlug}
            />
          </div>
          <div className="d-block mb-4">
            <ProfileBasicForm
              resourceDetails={resourceDetails}
              resourceSlug={resourceSlug}
            />
          </div>
          <div className="d-block mb-4">
            <ProfileContactForm
              resourceDetails={resourceDetails}
              resourceSlug={resourceSlug}
            />
          </div>
          <div className="d-block mb-4">
            <ResourceEducationBlock
              education_details={resourceDetails.education_details}
              resourceSlug={resourceSlug}
            />
          </div>
          <div className="d-block mb-4">
            <ResourceEmploymentBlock
              employment_details={resourceDetails.employment_details}
              resourceSlug={resourceSlug}
            />
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="d-block mb-4">
            <ResourceDocumentForm
              document_details={resourceDetails.document_details}
              resourceSlug={resourceSlug}
            />
          </div>
          <div className="d-block">
            <ResourceAvailabilityForm
              availability_details={resourceDetails.availability_details}
              resourceSlug={resourceSlug}
            />
          </div>
          <div className="d-block mt-4">
            <Button
              variant="danger"
              size="lg"
              className=""
              onClick={() => handleRemoveAccount(resourceSlug)}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditResourceFormBlock;
