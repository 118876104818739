import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import getUserDetails from "../../../apis/getUserDetails";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import EditFormBlock from "./EditFormBlock";

const EditManagerForm = (props) => {
  const { firm_access_self_services } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [userArray, setUserArray] = useState(true);
  const [selectedResourceEdit, setSelectedResourceEdit] = useState(false);

  useEffect(() => {
    if (firm_access_self_services) {
      console.log(firm_access_self_services);
      createFirmTab();
    }
  }, [firm_access_self_services]);

  const createFirmTab = () => {
    setSelectedResourceEdit(firm_access_self_services[0].user_slug);
  };

  useEffect(() => {
    if (selectedResourceEdit) {
      getResource();
    }
  }, [selectedResourceEdit]);

  const getResource = () => {
    if (selectedResourceEdit !== undefined) {
      setIsLoading(true);
      Promise.all([getUserDetails(selectedResourceEdit)])
        .then(async ([data]) => {
          if (data?.data?.user_details) {
            setIsLoading(false);
            setUserArray(data?.data?.user_details);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  };

  const displayMainContent = () => {
    if (isLoading === true) {
      return displayLoadingBlock();
    } else {
      return displayFormBlock();
    }
  };

  const displayLoadingBlock = () => {
    return <LoadingPageSm title={"Loading, please wait..."} />;
  };

  const displayFormBlock = () => {
    return (
      <EditFormBlock userDetails={userArray} userSlug={selectedResourceEdit} />
    );
  };

  const changeResource = (slug) => {
    setSelectedResourceEdit(slug);
  };

  return (
    <div className="d-block">
      <div className="d-flex mb-4">
        {firm_access_self_services.map((item) => {
          return (
            <button
              onClick={() => changeResource(item.user_slug)}
              type="button"
              className={`btn me-2 ${
                selectedResourceEdit === item.user_slug
                  ? "btn-primary"
                  : "btn-light"
              }`}
            >
              {item.firm_name}
            </button>
          );
        })}
      </div>
      {displayMainContent()}
    </div>
  );
};

export default React.memo(EditManagerForm);
