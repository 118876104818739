import axios from "axios";

//IMPORT API ROUTE URL
import { GET_MATCHING_CANDIDATE_V2_ROUTE } from "./apiRoutes";

const getMatchingCandidateV2 = (
  jobSlug,
  user_slug,
  jobScore,
  eduScore,
  skillScore,
  industryScore,
  lngScore,
  mlScore,
  cScore,
  exScore
) => {
  var postData = new FormData();

  postData.append("u_slug", user_slug);
  postData.append("j_slug", jobSlug);
  postData.append("j_score", jobScore);
  postData.append("e_score", eduScore);
  postData.append("s_score", skillScore);
  postData.append("i_score", industryScore);
  postData.append("l_score", lngScore);
  postData.append("m_score", mlScore);
  postData.append("c_score", cScore);
  postData.append("ex_score", exScore);

  return axios
    .post(GET_MATCHING_CANDIDATE_V2_ROUTE, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default getMatchingCandidateV2;
