import React from "react";
import TopProfileSection from "./TopProfileSection";

const ResourceManagerScreen = () => {
  return (
    <>
      <TopProfileSection />
    </>
  );
};

export default ResourceManagerScreen;
