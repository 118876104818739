import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import { AlertInfo } from "../../Alerts/Alert";
import ProfileImageMd from "../../CommonComponent/ProfileImageMd";
import { BadgeInfo } from "../../Badge/Badge";
import { Button } from "react-bootstrap";
import getMyResourceListing from "../../../apis/getMyResourceListing";
import updateIsAdvertised from "../../../apis/updateIsAdvertised";
import updateSelfService from "../../../apis/updateSelfService";
import { PieChart } from "react-minimal-pie-chart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapSigns } from "@fortawesome/free-solid-svg-icons";
import star from "../../../assets/images/star.png";
import Swal from "sweetalert2";
import swalWithBootstrapButtons from "sweetalert2-react-content";

const ResourceListingResultBlock = () => {
  let history = useHistory();
  const { userDetails } = useAuthContext();
  const MySwal = swalWithBootstrapButtons(Swal);
  const firmType = JSON.parse(userDetails).firm_details?.firm_type;
  const firmId = JSON.parse(userDetails).firm_details?.firm_id;
  const user_id = JSON.parse(userDetails).user_id;
  const user_slug = JSON.parse(userDetails).user_slug;
  const user_primary_role = JSON.parse(userDetails).user_primary_role;
  const [isLoading, setIsLoading] = useState(true);
  const [resourceListing, setResourceListing] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const [emptyResult, setEmptyResult] = useState("");
  const [filterText, setFilterText] = useState("");
  const [favList, setFavList] = useState(false);

  useEffect(() => {
    if (user_slug !== undefined) {
      const timeoutId = setTimeout(
        () => [setIsLoading(true), getResourceListing()],
        500
      );
      return () => clearTimeout(timeoutId);
    }
  }, [filterText, user_slug]);

  const getResourceListing = () => {
    Promise.all([getMyResourceListing(user_slug, filterText)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          if (data?.data?.resourceList) {
            setResourceListing(data?.data?.resourceList);
            if (data?.data?.fav_list) {
              setFavList(data?.data?.fav_list);
            }

            setIsLoading(false);
            setHasResult(true);
          } else {
            setIsLoading(false);
            setEmptyResult("No resource result found for your account access.");
          }
        } else {
          setIsLoading(false);
          setHasResult(false);
          setEmptyResult(data?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setEmptyResult(false);
        console.log(err);
      });
  };

  const displayMainContent = () => {
    if (isLoading === true) {
      return displayLoadingBlock();
    } else {
      return displayResourceListing();
    }
  };

  const displayLoadingBlock = () => {
    return <LoadingPageSm title={"Loading resources..."} />;
  };

  const displayResourceListing = () => {
    if (!hasResult) {
      return <AlertInfo title={"Note"} message={emptyResult} />;
    } else {
      return <>{displayList()}</>;
    }
  };

  const onKeyworkChange = (e) => {
    setFilterText(e.target.value);
  };

  const displaySearchResourceBlock = () => {
    return (
      <div className="col-12">
        <div className="d-flex">
          <div className="col-12 col-lg-5 col-xl-5 col-xxl-5">
            <input
              type="text"
              placeholder="Search for name/location/role"
              className="form-control text-capitalize"
              onChange={onKeyworkChange}
              value={filterText}
            />
          </div>
        </div>
      </div>
    );
  };

  const updateAdvertised = (advertised, resourceSlug, index) => {
    Promise.all([updateIsAdvertised(user_slug, advertised, resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          let updatedResourceList = [...resourceListing];

          if (advertised === "0") {
            advertised = "1";
          } else {
            advertised = "0";
          }

          updatedResourceList[index].is_advertised = advertised;

          setResourceListing(updatedResourceList);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setEmptyResult(false);
        console.log(err);
      });
  };

  const updateSelfServiceEdit = (resourceSlug, index, name) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `You want to update self-service access for ${name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "var(--danger)",
      cancelButtonColor: "var(--black)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        processUpdateSelfService(resourceSlug, index);
      } else {
        return false;
      }
    });
  };

  const processUpdateSelfService = (resourceSlug, index) => {
    Promise.all([updateSelfService(user_slug, resourceSlug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          let updatedResourceList = [...resourceListing];
          updatedResourceList[index] = data?.data?.resource;

          setResourceListing(updatedResourceList);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setEmptyResult(false);
        console.log(err);
      });
  };

  const navigateTpProfile = (path) => {
    history.push(path);
  };

  const displayFavotite = (uID) => {
    if (favList) {
      if (favList.includes(uID)) {
        return (
          <div className="profile-favorite-holder-left ms-1">
            <img className="img-fluid" src={star} />
          </div>
        );
      }
    }
  };

  const checkSelfServices = (firmIds) => {
    if (firmIds !== null) {
      const fIds = firmIds.split(", ");

      if (fIds.indexOf(firmId) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const displayList = () => {
    return (
      <>
        {resourceListing.map((item, index) => {
          return (
            <div key={index.toString()} className="d-block mb-3">
              <div className="card-custom">
                <div className="card-body">
                  <div className="d-block d-md-flex d-lg-flex d-xl-flex d-xxl-flex row align-items-center position-relative">
                    <div className="col-12 col-lg-1 col-xl-1 col-xxl-1 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 d-flex align-items-end">
                      <Link to={"resources/details/" + item.user_slug}>
                        <ProfileImageMd imgSrc={item.profile_image_path} />
                      </Link>
                      {displayFavotite(item.resource_id)}
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 col-xxl-2 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0">
                      <div className="d-block">
                        <Link to={"resources/details/" + item.user_slug}>
                          {displayName(item.resource_name)}
                        </Link>
                      </div>
                      <div className="d-block">
                        {displayUserProfileRole(item.user_profile_role)}
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 col-xxl-2 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0">
                      {user_primary_role === "2" ? (
                        <div className="form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={() =>
                              updateAdvertised(
                                item.is_advertised,
                                item.user_slug,
                                index
                              )
                            }
                            checked={item.is_advertised === "1" ? true : false}
                          />
                        </div>
                      ) : (
                        <div>
                          {item.is_advertised === "1" ? (
                            <div>
                              <span className="fw-bold text-success">Yes</span>
                            </div>
                          ) : (
                            <div>
                              <span className="fw-bold text-danger">No</span>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="d-block">
                        <span className="text-muted-custom">
                          Visible to Clients
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 col-xxl-2 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0">
                      {user_primary_role === "2" ? (
                        <div className="form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={() =>
                              updateSelfServiceEdit(
                                item.user_slug,
                                index,
                                item.resource_name
                              )
                            }
                            checked={checkSelfServices(
                              item.firm_ids_self_services
                            )}
                          />
                        </div>
                      ) : (
                        <div>
                          {checkSelfServices(item.firm_ids_self_services) ? (
                            <div>
                              <span className="fw-bold text-success">Yes</span>
                            </div>
                          ) : (
                            <div>
                              <span className="fw-bold text-danger">No</span>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="d-block">
                        <span className="text-muted-custom">Self-Service</span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 col-xxl-2 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0">
                      <div className="d-block">
                        <span className="text-primary">
                          <FontAwesomeIcon icon={faMapSigns} />
                        </span>
                      </div>
                      <div className="d-block">
                        <span className="text-muted-custom">
                          {" "}
                          {item.city_name}, {item.state_name},{" "}
                          {item.country_name}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-1 col-xl-1 col-xxl-1 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 d-flex flex-column align-items-start align-items-lg-center align-items-xl-center align-items-xxl-center">
                      {displayAvailability(item.availability)}
                      <div className="d-flex justify-content-center align-items-center">
                        <span className="text-dark fw-bold text-center">
                          {item.availability} hrs/week
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-start justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-1"
                        onClick={() =>
                          navigateTpProfile(
                            firmType === "1"
                              ? "my-team/details/" + item.user_slug
                              : "resources/details/" + item.user_slug
                          )
                        }
                      >
                        View Profile
                      </Button>
                      {user_primary_role === "2" &&
                      (user_id === item.resource_manager_id ||
                        item.firm_id === firmId) ? (
                        <Link
                          to={
                            firmType === "1"
                              ? "my-team/edit-resource/" + item.user_slug
                              : "resources/edit-resource/" + item.user_slug
                          }
                        >
                          <Button variant="primary" size="sm" className="ms-2">
                            Edit Profile
                          </Button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const displayAvailability = (availability) => {
    if (availability !== null) {
      availability = parseInt(availability);

      var color = "#DC143C";

      if (availability < 20) {
        color = "#DC143C";
      } else if (availability === 20 || availability === 30) {
        color = "#FFBF00";
      } else {
        color = "#32CD32";
      }

      return (
        <div className="chart-box-md">
          <PieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            center={[25, 25]}
            totalValue={40}
            data={[
              {
                color: color,
                value: availability,
              },
            ]}
            labelPosition={25}
            lengthAngle={360}
            lineWidth={30}
            paddingAngle={0}
            radius={25}
            startAngle={90}
            viewBoxSize={[50, 50]}
            background={"#ccc"}
          />
        </div>
      );
    } else {
      availability = 0;

      return (
        <div className="chart-box-md">
          <PieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            center={[50, 50]}
            totalValue={40}
            data={[
              {
                color: color,
                value: availability,
              },
            ]}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={30}
            paddingAngle={0}
            radius={50}
            startAngle={0}
            viewBoxSize={[50, 50]}
            background={"#ccc"}
          />
        </div>
      );
    }
  };

  const displayUserProfileRole = (user_profile_role) => {
    if (user_profile_role !== null) {
      return <span className="text-info-custom">{user_profile_role}</span>;
    } else {
      return <span className="text-danger">Not available</span>;
    }
  };

  const displayName = (name) => {
    if (name !== null) {
      if (name.length > 20) {
        return (
          <span className="fw-bold text-dark h6">
            {name.substring(0, 20) + "..."}
          </span>
        );
      } else {
        return <span className="fw-bold text-dark h6">{name}</span>;
      }
    } else {
      return <BadgeInfo title={"Not available"} />;
    }
  };

  return (
    <div className="d-block">
      <div className="d-flex row my-3">{displaySearchResourceBlock()}</div>
      {displayMainContent()}
    </div>
  );
};

export default ResourceListingResultBlock;
