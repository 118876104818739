import React, { useState, useRef, useCallback } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import HeaderXSm from "../Headers/HeaderXSm";
import InputLebelComponent from "../InputLebel/InputLebelComponent";
import { Button } from "react-bootstrap";
import { AlertDanger, AlertInfo, AlertSuccess } from "../Alerts/Alert";
import BlockHeader from "../Headers/BlockHeader";
//import API
import createResource from "../../apis/createResource";
import createResourceFromResume from "../../apis/createResourceFromResume";
// AddResourceForm.css
import "../Resources/AddResourceForm.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomConfirmationModal from "./CustomConfirmationModal";
import { Modal } from "bootstrap";
const AddResourceForm = () => {
  const history = useHistory();
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const user_primary_role = JSON.parse(userDetails).user_primary_role;
  const firmType = JSON.parse(userDetails).firm_details?.firm_type;
  // const hiddenFileInput = React.useRef(null);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [bio, setBio] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [buttonText, setButtonText] = useState("Create Account");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [isValidSubmit, setIsValidSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isResumeButtonDisabled, setIsResumeButtonDisabled] = useState(false);
  const [hasResumeUploadError, setHasResumeUploadError] = useState(false);
  const [isValidResumeUpload, setIsValidResumeUpload] = useState(false);
  const [errorResumeUploadMessage, setErrorResumeUploadMessage] =
    useState(false);
  const [successResumeUploadMessage, setSuccessResumeUploadMessage] =
    useState(false);
  const [isViaResumeParsing, setViaResumeParsing] = useState(true);
  const [modal, setModal] = useState(null);
  // drag and drop file usestate i am start code here
  const [isDrag, setIsDrag] = useState(false);
  const [dropFiles, setDropFiles] = useState([]);
  const inputFileRef = useRef(null);

  const fileSelect = () => {
    inputFileRef.current.click();
  };

  const onFileSelect = (e) => {
    const files = e.target ? e.target.files : e.dataTransfer.files;
    const droppedFiles = Array.from(files);
    if (e.target.files.length + dropFiles.length > 20) {
      toast.warn(
        "Max limit reached cannot upload more than 20 files at a time.",
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    }

    const filesWithUrls = droppedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      error: "",
      success: "",
    }));

    setDropFiles([...dropFiles, ...filesWithUrls]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDrag(true);
    e.dataTransfer.dropEffect = "copy";
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDrag(false);
  };

  const onConfirm = () => {
    // Remove error files from dropFiles
    const validFiles = dropFiles.filter((file) => !file.error);

    setDropFiles([...validFiles]);
    modal.hide();
  };
  const onCancel = () => {
    const validFiles = dropFiles.filter((file) => file.error);
    setDropFiles([...validFiles]);
  };
  const onDrop = (e) => {
    e.preventDefault();
    setIsDrag(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    // const validFiles = dropFiles.filter((file) => !file.error);
    const filesWithUrls = droppedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      error: "",
      success: "",
    }));
    setDropFiles([...dropFiles, ...filesWithUrls]);
    if (hasErrorFiles()) {
      let myModal = new Modal(document.getElementById("staticBackdrop"), {
        keyboard: false,
      });
      setModal(myModal);
      myModal.show();
    }
  };

  const hasErrorFiles = () => {
    // Check if dropFiles array has any error files
    return dropFiles.some((file) => file.error);
  };

  const uploadFiles = async () => {
    const updatedFiles = [];
    for (let i = 0; i < dropFiles.length; i++) {
      try {
        const res = await uploadResume(dropFiles[i]);
        updatedFiles.push({
          ...dropFiles[i],
          success: res,
        });
      } catch (err) {
        let errMsg = JSON.parse(err);
        updatedFiles.push({
          ...dropFiles[i],
          error: errMsg.message,
          user_slug: errMsg.user_slug,
        });
      }
    }

    // Filter out successfully uploaded files
    const remainingFiles = updatedFiles.filter((file) => !file.success);

    // Update the state with the remaining files

    setDropFiles(remainingFiles);
  };

  // delete file her
  const deleteFile = (index) => {
    setDropFiles(dropFiles.filter((_, i) => i !== index));
  };

  // error message warning

  const waringErr = (
    <div>
      <span>
        Click <span className="fw-bold">OK</span> to remove the error messages
        below
      </span>
      <br />
      <br />
      <span>
        <span className="fw-bold ">Note: </span>
        <span className="text-danger">
          Error messages must be cleared before uploading additional resumes for
          profile creation.
        </span>
      </span>
    </div>
  );

  // here is the end of my code
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleJobRoleChange = (e) => {
    setJobRole(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleContactEmailChange = (e) => {
    setContactEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleOfficePhoneChange = (e) => {
    setOfficePhone(e.target.value);
  };

  const displayMainContent = () => {
    return displayFormBlock();
  };

  const handleFormSubmit = () => {
    setIsButtonDisabled(true);
    setButtonText("Processing, please wait...");
    setHasSubmitError(false);
    setErrorMessage(false);
    setIsValidSubmit(false);
    setSuccessMessage(false);

    const emailPattern =
      /^([\w-.]+@(?!mail\.ru)(?!yandex\.ru)(?!mail\.com)([\w-]+.)+[\w-]{2,4})?$/;

    const phonePattern =
      /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

    let isInvalid = 0;
    let errMessage = [];

    if (firstName.trim().length === 0) {
      isInvalid = 1;
      errMessage.push("Enter first name");
    }

    if (lastName.trim().length === 0) {
      isInvalid = 1;
      errMessage.push("Enter last name");
    }

    if (!emailPattern.test(email)) {
      isInvalid = 1;
      errMessage.push("Enter a valid business email address");
    }

    if (!emailPattern.test(contactEmail)) {
      isInvalid = 1;
      errMessage.push("Enter a valid contact email address");
    }

    if (phone.trim().length > 0) {
      if (!phonePattern.test(phone)) {
        isInvalid = 1;
        errMessage.push("Enter a valid phone number");
      }
    }

    if (isInvalid === 1) {
      setErrorMessage(errMessage);
      setHasSubmitError(true);
      setIsButtonDisabled(false);
      setButtonText("Create Account");
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    let errMessage = [];
    let succMessage = [];

    let formData = {
      user_slug: user_slug,
      email: email,
      firstName: firstName,
      lastName: lastName,
      jobRole: jobRole,
      profileBio: bio,
      contactEmail: contactEmail,
      phone: phone,
      officePhone: officePhone,
    };

    try {
      createResource(formData).then(async (data) => {
        if (data?.data) {
          if (data.data.status === 1) {
            succMessage.push(data.data.message);
            setSuccessMessage(succMessage);
            setIsValidSubmit(true);
            setHasSubmitError(false);
            setIsButtonDisabled(true);
            setButtonText("Create Account");

            setTimeout(() => {
              window.location.replace(
                "resources/edit-resource/" + data.data.resource_slug
              );
            }, 1000);
          } else if (data.data.status === 0) {
            errMessage.push(data.data.message);
            setErrorMessage(errMessage);
            setHasSubmitError(true);
            setIsButtonDisabled(false);
            setButtonText("Create Account");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            errMessage.push(
              "Error happened. Unable to create profile information."
            );
            setErrorMessage(errMessage);
            setHasSubmitError(true);
            setIsButtonDisabled(false);
            setButtonText("Create Account");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          errMessage.push(
            "Error happened. Unable to create your profile information"
          );
          setErrorMessage(errMessage);
          setHasSubmitError(true);
          setIsButtonDisabled(false);
          setButtonText("Create Account");

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    } catch (error) {
      errMessage.push("Error happened. Network error happened.");
      setErrorMessage(errMessage);
      setHasSubmitError(true);
      setIsButtonDisabled(false);
      setButtonText("Create Account");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const displayTopBlock = () => {
    return (
      <BlockHeader
        title={
          firmType === "1"
            ? "Create Team Member Profile"
            : "Create Candidate Profiles"
        }
        subText={
          "Add up to 20 resume files at a time to automatically create candidate profiles"
        }
      />
    );
  };

  const displayResumeUpload = () => {
    return (
      <>
        <div className="d-block">
          <CustomConfirmationModal
            message={waringErr}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />

          {isResumeButtonDisabled ? (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="d-block my-2">{displayResumeUploadStatusMessage()}</div>
        <div
          className=" card12 p-4 rounded shadow"
          style={{ width: "80%", overflow: "hidden", margin: "auto" }}
        >
          <div className="text-center">
            <p className="text-primary fw-bold">Drag and Drop files here</p>
          </div>

          <div
            className="drag-area12 rounded-3 bg-light d-flex justify-content-center align-items-center "
            style={{ height: "250px" }}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            {isDrag ? (
              <>
                <span className="select12 ">Drop files here</span>
              </>
            ) : (
              <>
                {dropFiles.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                    <i className="bi bi-upload file-icon12"></i>
                    <span className="drop-file12">Drop files here or</span>
                    <span
                      className="select12 d-flex gap-2 btn btn-primary"
                      role="button"
                      onClick={fileSelect}
                    >
                      <i className="bi bi-file-earmark-plus"></i>
                      <span>Click to upload</span>
                    </span>
                  </div>
                ) : (
                  <div className="filee-container12">
                    {dropFiles.map((file, index) => (
                      <div className="file-preview12" key={index}>
                        <ul className="ul-file-list12">
                          <li>
                            {file.file.name}

                            <span className="text-danger">{file.error}</span>
                            {file.error && file.user_slug ? (
                              <span>
                                Click{" "}
                                <a
                                  href={`resources/details/${file.user_slug}`}
                                  target="_blank"
                                >
                                  here
                                </a>{" "}
                                to view existing profile.
                              </span>
                            ) : (
                              ""
                            )}
                            <span
                              className="delete12"
                              onClick={() => deleteFile(index)}
                            >
                              &times;
                            </span>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            <input
              type="file"
              name="file"
              className="file12"
              multiple
              ref={inputFileRef}
              onChange={onFileSelect}
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </div>

          <div
            className={
              isDrag || dropFiles.length === 0
                ? "file-disable12"
                : "file-button12"
            }
          >
            <button
              onClick={uploadFiles}
              disabled={isDrag || dropFiles.length === 0}
            >
              Upload
            </button>
          </div>
        </div>
      </>
    );
  };
  const uploadResume = ({ file }) => {
    return new Promise((resolve, reject) => {
      setIsResumeButtonDisabled(true);
      let errMessage = [];
      let succMessage = [];
      let formData = {
        user_slug: user_slug,
        file: file,
      };
      try {
        createResourceFromResume(formData).then(async (data) => {
          setIsResumeButtonDisabled(false);
          if (data?.data) {
            if (data.data.status === 0) {
              errMessage.push(data.data.message);
              setErrorResumeUploadMessage(errMessage);
              toast.error(`${errMessage}`, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
              });
              reject(JSON.stringify(data?.data));
            } else {
              setIsResumeButtonDisabled(false);
              succMessage.push(data.data.message);

              toast.success(`${succMessage}`, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
              });
              resolve(data.data.message);
            }
          } else {
            toast.warn("Something wrong happened. Please try again later.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const displayForm = () => {
    return (
      <>
        <div className="d-block my-3">
          <strong>Please Note</strong>{" "}
          <span className="text-info-custom">
            ***Fill up all fields to create a Candidate account
          </span>
        </div>
        <div className="d-block">
          <div id="create-frm">
            {displayloginBlock()}
            {displayprofileBlock()}
            {displayContactBlock()}
            {displaySubmitButton()}
            {displayStatusMessage()}
          </div>
        </div>
      </>
    );
  };

  const displayloginBlock = () => {
    return (
      <div className="card-custom mb-4">
        <HeaderXSm
          title={"Add login informations"}
          subText={"These informations will be used while login"}
          borderBottom={true}
        />
        <div className="card-body">
          <div className="d-block d-md-flex d-lg-flex d-xl-flex row">
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Email Address" />
                <div className="d-block">
                  <input
                    type="email"
                    className="form-control"
                    id="email-address"
                    placeholder="Enter email address"
                    onChange={handleEmailChange}
                    value={email}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displayprofileBlock = () => {
    return (
      <div className="card-custom mb-4">
        <HeaderXSm
          title={"Add profile information"}
          subText={"These informations will be used in profile view"}
          borderBottom={true}
        />

        <div className="card-body">
          <div className="d-block d-md-flex d-lg-flex d-xl-flex row">
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="First Name" />
                <div className="d-block">
                  <input
                    type="text"
                    className="form-control"
                    id="first-name"
                    placeholder="Enter first name"
                    onChange={handleFirstNameChange}
                    value={firstName}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Last Name" />
                <div className="d-block">
                  <input
                    type="text"
                    className="form-control"
                    id="last-name"
                    placeholder="Enter last name"
                    onChange={handleLastNameChange}
                    value={lastName}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Job Role" />
                <div className="d-block">
                  <input
                    type="text"
                    className="form-control"
                    id="last-name"
                    placeholder="Enter job role"
                    onChange={handleJobRoleChange}
                    value={jobRole}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xlg-12">
              <div className="form-input-holder">
                <InputLebelComponent title="Profile Bio" />
                <div className="d-block">
                  <textarea
                    type="textarea"
                    className="form-control"
                    id="job-role"
                    placeholder="Enter your bio"
                    onChange={handleBioChange}
                    value={bio}
                    autoComplete="off"
                    rows={6}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displayContactBlock = () => {
    return (
      <div className="card-custom mb-2">
        <HeaderXSm
          title={"Add contact information"}
          subText={"These informations will be used in profile"}
          borderBottom={true}
        />

        <div className="card-body">
          <div className="d-block d-md-flex d-lg-flex d-xl-flex row">
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Contact Email" />
                <div className="d-block">
                  <input
                    type="email"
                    className="form-control"
                    id="contact-email"
                    placeholder="Enter contact email"
                    onChange={handleContactEmailChange}
                    value={contactEmail}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Phone Number" />
                <div className="d-block">
                  <input
                    type="text"
                    className="form-control"
                    id="phone-number"
                    placeholder="Enter phone number"
                    onChange={handlePhoneChange}
                    value={phone}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Office Phone" />
                <div className="d-block">
                  <input
                    type="text"
                    className="form-control"
                    id="office-phone-number"
                    placeholder="Enter office phone number"
                    onChange={handleOfficePhoneChange}
                    value={officePhone}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displaySubmitButton = () => {
    return (
      <div className="form-button-holder justify-content-end mt-4">
        <Button
          variant="primary"
          disabled={isButtonDisabled}
          onClick={handleFormSubmit}
        >
          {buttonText}
        </Button>
      </div>
    );
  };

  const displayStatusMessage = () => {
    return (
      <div className="d-block mt-4">
        {displayErrorMessage()} {displaySuccessMessage()}
      </div>
    );
  };

  const displayErrorMessage = () => {
    if (hasSubmitError) {
      return <AlertDanger title={"Oops"} message={errorMessage} />;
    }
  };

  const displaySuccessMessage = () => {
    if (isValidSubmit) {
      return <AlertSuccess title={"Success"} message={successMessage} />;
    }
  };

  const displayResumeUploadStatusMessage = () => {
    return (
      <div className="d-block mt-4">
        {displayResumeUploadErrorMessage()}{" "}
        {displayResumeUploadSuccessMessage()}
      </div>
    );
  };

  const displayResumeUploadErrorMessage = () => {
    if (hasResumeUploadError) {
      return <AlertDanger title={"Oops"} message={errorResumeUploadMessage} />;
    }
  };

  const displayResumeUploadSuccessMessage = () => {
    if (isValidResumeUpload) {
      return (
        <AlertSuccess title={"Success"} message={successResumeUploadMessage} />
      );
    }
  };

  const displayFormBlock = () => {
    if (user_primary_role === "2") {
      return (
        <>
          {displayTopBlock()}
          <div className="d-flex justify-content-between mb-2">
            <Button variant="primary" onClick={() => setViaResumeParsing(true)}>
              Via Resume Parsing
            </Button>
            <Button
              variant="primary"
              onClick={() => setViaResumeParsing(false)}
            >
              Manual Entry
            </Button>
          </div>
          {isViaResumeParsing ? displayResumeUpload() : displayForm()}
        </>
      );
    } else {
      return (
        <AlertInfo
          title={"Note"}
          message={"You do not have access to create Resource"}
        />
      );
    }
  };

  return <div className="d-block">{displayMainContent()}</div>;
};

export default AddResourceForm;
