import React, { useState, useEffect } from "react";

import DashboardComponent from "../../components/DashboardComponent/DashboardComponent";
import Layout from "../../components/Layouts/WithAuth/Layout";
import { useAuthContext } from "../../context/AuthContext";
import EditForm from "../../components/MyProfile/ResourceEditProfile/EditForm";

const DashboardScreen = () => {
  const { userDetails } = useAuthContext();
  const user_primary_role = JSON.parse(userDetails).user_primary_role;
  const user_slug = JSON.parse(userDetails).user_slug;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user_slug) {
      setIsLoading(false);
    }
  }, [user_slug]);

  return (
    <Layout pageTitle={`FirmConnector :: My Dashboard`}>
      {!isLoading ? (
        <>{user_primary_role === "3" ? <EditForm /> : <DashboardComponent />}</>
      ) : null}
    </Layout>
  );
};

export default DashboardScreen;
