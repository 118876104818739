import React from "react";
import ProfileBasicForm from "./ProfileBasicForm";
import ProfileContactForm from "./ProfileContactForm";
import ResourceAvailabilityForm from "./ResourceAvailabilityForm";
import AvailabilityHolder from "../../Resources/ResourceProfile/AvailabilityHolder";
import ResourceEducationBlock from "./ResourceEducationBlock";
import ResourceEmploymentBlock from "./ResourceEmploymentBlock";
import CertificationSet from "./CertificationSet";
import Language from "./Language";
import ProfilePasswordResetForm from "../ProfilePasswordResetForm";

const EditFormBlock = (props) => {
  const { userDetails, userSlug } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-8 col-xl-8 col-xxl-8">
          <div className="col-12 ">
            <div className="d-block mb-4">
              <ProfileBasicForm
                userDetails={userDetails.profile_details}
                userSlug={userSlug}
              />
            </div>
          </div>
          <div className="col-12 ">
            <div className="d-block mb-4">
              <ProfileContactForm
                resourceDetails={userDetails.contact_details}
                resourceSlug={userSlug}
              />
            </div>
          </div>
          <div className="col-12 ">
            <div className="d-block mb-4">
              <ResourceEducationBlock
                education_details={userDetails.education_details}
                resourceSlug={userSlug}
              />
            </div>
          </div>
          <div className="col-12 ">
            <div className="d-block mb-4">
              <ResourceEmploymentBlock
                employment_details={userDetails.employment_details}
                resourceSlug={userSlug}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-4 col-xxl-4">
          <div className="col-12">
            <div className="d-block mb-4">
              <ProfilePasswordResetForm userSlug={userSlug} />
            </div>
          </div>

          <div className="col-12">
            <div className="d-block mb-4">
              <ResourceAvailabilityForm
                availability_details={userDetails.availability_details}
                resourceSlug={userSlug}
              />
            </div>
          </div>
          <div className="col-12 mb-4">
            <AvailabilityHolder
              availabilityDetails={userDetails.availability_details}
            />
          </div>
          <div className="col-12 mb-4">
            <CertificationSet
              certificationDetails={userDetails.certification_details}
              resourceSlug={userSlug}
            />
          </div>
          <div className="col-12 mb-4">
            <Language
              languageDetails={userDetails.language_details}
              resourceSlug={userSlug}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFormBlock;
