import axios from "axios";

//IMPORT API ROUTE URL
import { GET_LINK_DETAILS_ROUTE } from "./apiRoutes";

const getVLinkDetails = (link) => {
  var postData = new FormData();

  postData.append("link", link);

  return axios
    .post(GET_LINK_DETAILS_ROUTE, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default getVLinkDetails;
