import axios from "axios";

//IMPORT API ROUTE URL
import { RECENTE_SEARCHES_ROUTE } from "./apiRoutes";

const recentSearches = (user_slug) => {
  var postData = new FormData();

  postData.append("user_slug", user_slug);

  return axios
    .post(RECENTE_SEARCHES_ROUTE, postData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return false;
    });
};

export default recentSearches;
