import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { AlertDanger, AlertSuccess } from "../Alerts/Alert";
import InputLebelComponent from "../InputLebel/InputLebelComponent";
import HeaderXSm from "../Headers/HeaderXSm";
import updateProfilePassword from "../../apis/updateProfilePassword";

const ProfilePasswordResetForm = (props) => {
  const { userSlug } = props;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [buttonText, setButtonText] = useState("Update Password");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [isValidSubmit, setIsValidSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfPasswordChange = (e) => {
    setConfPassword(e.target.value);
  };

  const handleFormSubmit = () => {
    setIsButtonDisabled(true);
    setButtonText("Processing, please wait...");
    setHasSubmitError(false);
    setErrorMessage(false);
    setIsValidSubmit(false);
    setSuccessMessage(false);

    let isInvalid = 0;
    let errMessage = [];

    if (oldPassword.trim().length === 0) {
      isInvalid = 1;
      errMessage.push("Enter old password");
    }

    if (newPassword.trim().length === 0) {
      isInvalid = 1;
      errMessage.push("Enter new password");
    } else {
      if (newPassword.trim().length < 6) {
        isInvalid = 1;
        errMessage.push(
          "New password length should be at least 6 characters long"
        );
      }
    }

    if (confPassword.trim().length === 0) {
      isInvalid = 1;
      errMessage.push("Enter confirm password");
    }

    if (confPassword.trim().length > 0 && newPassword.trim().length > 0) {
      if (confPassword.trim() !== newPassword.trim()) {
        isInvalid = 1;
        errMessage.push("New password and confirm password should be same.");
      }
    }

    if (isInvalid === 1) {
      setErrorMessage(errMessage);
      setHasSubmitError(true);
      setIsButtonDisabled(false);
      setButtonText("Update Password");
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    let errMessage = [];
    let succMessage = [];

    let formData = {
      userSlug: userSlug,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    try {
      updateProfilePassword(formData).then(async (data) => {
        if (data?.data) {
          if (data.data.status === 1) {
            setOldPassword("");
            setNewPassword("");
            setConfPassword("");
            succMessage.push(data.data.message);
            setSuccessMessage(succMessage);
            setIsValidSubmit(true);
            setHasSubmitError(false);
            setIsButtonDisabled(false);
            setButtonText("Update Password");

            setTimeout(() => {
              setIsValidSubmit(false);
              setHasSubmitError(false);
              setErrorMessage(false);
              setSuccessMessage(false);
            }, 2000);
          } else if (data.data.status === 0) {
            errMessage.push(data.data.message);
            setErrorMessage(errMessage);
            setHasSubmitError(true);
            setIsButtonDisabled(false);
            setButtonText("Update Password");
          } else {
            errMessage.push("Error happened. Unable to update your password.");
            setErrorMessage(errMessage);
            setHasSubmitError(true);
            setIsButtonDisabled(false);
            setButtonText("Update Password");
          }
        } else {
          errMessage.push("Error happened. Unable to update your password");
          setErrorMessage(errMessage);
          setHasSubmitError(true);
          setIsButtonDisabled(false);
          setButtonText("Update Password");
        }
      });
    } catch (error) {
      errMessage.push("Error happened. Network error happened.");
      setErrorMessage(errMessage);
      setHasSubmitError(true);
      setIsButtonDisabled(false);
      setButtonText("Update Password");
    }
  };

  const displaySubmitButton = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          variant="primary"
          disabled={isButtonDisabled}
          onClick={handleFormSubmit}
          size="sm"
        >
          {buttonText}
        </Button>
      </div>
    );
  };

  const displayStatusMessage = () => {
    return (
      <>
        {displayErrorMessage()} {displaySuccessMessage()}
      </>
    );
  };

  const displayErrorMessage = () => {
    if (hasSubmitError) {
      return (
        <div className="d-block mt-4">
          <AlertDanger title={"Oops"} message={errorMessage} />
        </div>
      );
    }
  };

  const displaySuccessMessage = () => {
    if (isValidSubmit) {
      return (
        <div className="d-block mt-4">
          <AlertSuccess title={"Success"} message={successMessage} />
        </div>
      );
    }
  };

  return (
    <div className="card-custom">
      <HeaderXSm title={"Reset Password"} subText={null} borderBottom={true} />
      <div className="card-body">
        <form id="create-frm">
          <div className="d-block d-md-flex d-lg-flex d-xl-flex row">
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Old Password" />
                <div className="d-block">
                  <input
                    type="password"
                    className="form-control"
                    id="old-password"
                    placeholder="Enter old password"
                    onChange={handleOldPasswordChange}
                    value={oldPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="New Password" />
                <div className="d-block">
                  <input
                    type="password"
                    className="form-control"
                    id="new-password"
                    placeholder="Enter new password"
                    onChange={handleNewPasswordChange}
                    value={newPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xlg-6">
              <div className="form-input-holder">
                <InputLebelComponent title="Confirm New Password" />
                <div className="d-block">
                  <input
                    type="password"
                    className="form-control"
                    id="confirm-password"
                    placeholder="Enter new password"
                    onChange={handleConfPasswordChange}
                    value={confPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          {displaySubmitButton()}
          {displayStatusMessage()}
        </form>
      </div>
    </div>
  );
};

export default ProfilePasswordResetForm;
