import React from "react";
import MyFavorite from "./MyFavorite";
import RecentSearches from "./RecentSearches";
import ActiveJobs from "./ActiveJobs";
import FirmInNetwork from "./FirmInNetwork";

const BottomComponent = () => {
  return (
    <div className="d-block">
      <div className="d-block mt-4">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <MyFavorite />
          </div>
          <div className="col-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <RecentSearches />
          </div>
        </div>
      </div>

      <div className="d-block mb-4">
        <div className="row">
          <div className="col-12 col-lg-8 col-xl-8 col-xxl-8 mb-4">
            <ActiveJobs />
          </div>
          <div className="col-12 col-lg-4 col-xl-4 col-xxl-4 mb-4">
            <FirmInNetwork />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomComponent;
