import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FIRM_IMAGE_BASE } from "../../../config/env";
import { Button } from "react-bootstrap";
import { useAuthContext } from "../../../context/AuthContext";

const LatestJobListingResultBlock = (props) => {
  const { jobList, handleRemoveJob } = props;
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;

  const displayDiscription = (discription) => {
    if (discription.length > 400) {
      return discription.substr(0, 400) + "...";
    } else {
      return discription;
    }
  };

  const removeJob = (jobSlug) => {
    handleRemoveJob(jobSlug);
  };

  const jobItem = (item) => {
    return (
      <div key={item.job_id} className="d-block mb-3">
        <div className="card-custom">
          <div className="card-body">
            <div className="d-block">
              <h5>
                <Link to={`/job/details/${item.job_slug}`}>
                  <span className="text-info-custom h5">
                    {item.job_id} :: {item.job_title}
                  </span>
                </Link>
              </h5>
              <span className="fw-bold">{item.employer_name}</span>
            </div>
            <div className="d-block my-3">
              <span className="text-dark">
                {displayDiscription(item.job_description)}
              </span>
            </div>
            <div className="row d-flex align-items-center rounded py-2 bg-light m-0">
              <div className="col-12 col-lg-3 col-xl-3 col-xxl-3 mb-2 mb-lg-0 mb-xl-0 mb-xxl-0">
                <div className="d-block">
                  <span className="text-dark">Posted By</span>
                </div>
                <div className="d-block">
                  <span className="fw-bold">{item.creator_name}</span>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-xl-3 col-xxl-3 mb-2 mb-lg-0 mb-xl-0 mb-xxl-0">
                <div className="d-block">
                  <span className="text-dark">Posted On</span>
                </div>
                <div className="d-block">
                  <span className="fw-bold">
                    {moment(item.created_on).format("MMMM Do, YYYY")}
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-xl-3 col-xxl-3 mb-2 mb-lg-0 mb-xl-0 mb-xxl-0">
                <div className="d-block">
                  <span className="text-dark">Closing Date</span>
                </div>
                <div className="d-block">
                  <span className="fw-bold">
                    {item.closing_date !== null && item.closing_date !== ""
                      ? moment(item.closing_date).format("MMMM Do, YYYY")
                      : "Unknown"}
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-xl-3 col-xxl-3 mb-2 mb-lg-0 mb-xl-0 mb-xxl-0">
                <div className="d-block">{displayFirm(item.firm_logo)}</div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end p-3">
            <Link to={`/job/details/${item.job_slug}`}>
              <Button variant="warning" size="sm">
                View Job
              </Button>
            </Link>
            {user_slug === item.user_slug ? (
              <>
                <Link to={`/job/edit/${item.job_slug}`}>
                  <Button variant="success" size="sm" className="ms-3">
                    Edit Job
                  </Button>
                </Link>
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-3"
                  onClick={() => removeJob(item.job_slug)}
                >
                  Remove Job
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const displayFirm = (logo_path) => {
    return (
      <div
        className="firm-logo-sm-custom"
        style={{
          backgroundImage: `url("${FIRM_IMAGE_BASE + logo_path}")`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    );
  };

  return (
    <div className="d-block">
      {jobList
        ? jobList.map(function (item) {
            return jobItem(item);
          })
        : null}
    </div>
  );
};

export default LatestJobListingResultBlock;
